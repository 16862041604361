import { ExclamationCircleIcon } from '@heroicons/react/outline'
import PropTypes from 'prop-types'
import React from 'react'
import store from 'store'
import Label from './Label'
export default function Input(props) {
  let settingsData = store.get('userData')
  const getInputTypeForType = (type) => {
    switch (type) {
      case 'currency':
        return 'text'
      default:
        return type
    }
  }

  const formatLabel = (str) => {
    return str.replace(/[^a-zA-Z]/g, '')
  }

  const onChange = (e) => {
    return props.onChange(e.target.value)
  }

  return (
    <div className={props.containerClass} id={props.id || ''}>
      <Label title={props.label} />
      <div className="mt-1 relative rounded-md shadow-sm">
        {props.lines > 1 ? (
          <textarea
            type="text"
            name={formatLabel(props.label)}
            id={formatLabel(props.label)}
            className="disabled:bg-gray shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder={props.placeholder}
            aria-describedby={formatLabel(props.label)}
            rows={props.lines}
            value={props.value}
            onChange={onChange}
            disabled={props.disabled}
            style={
              props.disabled ? { backgroundColor: '#d1d5db', opacity: 0.5 } : {}
            }
          />
        ) : (
          <input
            type={getInputTypeForType(props.type)}
            name={formatLabel(props.label)}
            id={formatLabel(props.label)}
            className="disabled:bg-gray shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder={props.placeholder}
            aria-describedby={formatLabel(props.label)}
            value={props.value}
            onChange={onChange}
            disabled={props.disabled}
            style={
              props.disabled ? { backgroundColor: '#d1d5db', opacity: 0.5 } : {}
            }
          />
        )}

        {props.errorText && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}

        {props.type === 'currency' && (
          <div className="absolute inset-y-0 right-0 flex items-center">
            <label htmlFor="currency" className="sr-only">
              Currency
            </label>
            <select
              id="currency"
              name="currency"
              className="focus:ring-green-500 focus:border-green-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
            >
              <option>{settingsData.shop.currency ? settingsData.shop.currency.toUpperCase()  : 'USD'}</option>
            </select>
          </div>
        )}
      </div>
      {props.helpText && (
        <p className="mt-2 text-sm text-gray-500" id="email-description">
          {props.helpText}
        </p>
      )}
      {props.errorText && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {props.errorText}
        </p>
      )}
    </div>
  )
}

Input.propTypes = {
  containerClass: PropTypes.string,
  errorText: PropTypes.string,
  helpText: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  lines: PropTypes.number,
}

Input.defaultProps = {
  errorText: '',
  helpText: '',
  label: 'name',
  placeholder: 'Enter name',
  type: 'text',
  containerClass: '',
  lines: 1,
}
