import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import CustomerAction, {
  CustomerTypes,
  CustomerSelectors,
} from 'Redux/CustomerRedux'
import { ItemsPerPage } from 'Redux/genericReducers'

export function* getAll(api, action) {
  let params = action.params || {}

  if (typeof action.listDataOffset !== 'undefined') {
    params.offset = action.listDataOffset
  } else {
    const listDataOffset = yield select(CustomerSelectors.listDataOffset)
    params.offset = listDataOffset
  }
  params.limit = ItemsPerPage

  const resp = yield call(api.getCustomers, action.params)
  if (resp.ok) {
    yield put(CustomerAction.customersListSuccess(resp.data))
    if (action.callback) {
      action.callback(resp.data)
    }
  } else {
    yield put(CustomerAction.customersListFailure('something went wrong'))
  }
}

export function* detailsRequest(api, action) {
  const resp = yield call(api.getCustomerDetails, action.id)
  if (resp.ok) {
    yield put(CustomerAction.customersDetailSuccess(resp.data))
  } else {
    yield put(CustomerAction.customersDetailFailure('something went wrong'))
  }
}

export function* updateAllCustomer(api, action) {
  const resp = yield call(api.updateAllCustomer, action.params)
  if (resp.ok) {
    action.callback(resp.data)
  } else {
    action.callback('failure')
  }
}

export function* getCustomerMessages(api, action) {
  const resp = yield call(api.getCustomerMessages, action.params)
  if (resp.ok) {
    action.callback(resp.data)
  } else {
    action.callback('failure')
  }
}

export function* markAsRead(api, action) {
  const resp = yield call(api.markAsRead, action.params)
  if (resp.ok) {
    action.callback(resp.data)
  } else {
    action.callback('failure')
  }
}

export default function* authSagas(api) {
  yield all([
    takeLatest(CustomerTypes.CUSTOMERS_LIST_REQUEST, getAll, api),
    takeLatest(CustomerTypes.CUSTOMERS_DETAIL_REQUEST, detailsRequest, api),
    takeLatest(CustomerTypes.UPDATE_ALL_CUSTOMER, updateAllCustomer, api),
    takeLatest(CustomerTypes.CUSTOMER_MESSAGES, getCustomerMessages, api),
    takeLatest(CustomerTypes.MARK_AS_READ, markAsRead, api),
  ])
}
