import Modal from 'Components/Modal'
import RadioButton from 'Components/RadioButton'
import React, { useEffect, useState } from 'react'
import { bundleUrl, getPublicUrl } from 'Themes/constants'
import store from 'store'

const EmbedCodeModal = (props) => {
  const [snippet, setSnippet] = useState('')
  const [snippetType, setSnippetType] = useState(1)
  const [currentProductUuid, setCurrentProductUuid] = useState()
  const [currentProductHandle, setCurrentProductHandle] = useState('')

  const userData = store.get('userData')

  const copyEmbedCodeSnippet = () => {
    navigator.clipboard.writeText(snippet)
    props.onClick()
  }

  const scriptTypes = [
    { value: 1, name: 'Embed' },
    { value: 2, name: 'Floating Button' },
    { value: 3, name: 'Popup' },
  ]

  useEffect(() => {
    if (props.selectedProductId) {
      const productUuid = props?.servicesList.find(
        (service) => service.id == props.selectedProductId
      )?.product_uuid
      const productHandle = props?.servicesList.find(
        (service) => service.id == props.selectedProductId
      )?.handle

      setCurrentProductHandle(productHandle)
      setCurrentProductUuid(productUuid)
      const embedCodeSnippet = `<script type="text/javascript">
       window.appointo = {
        config:{
          shop_uuid: '${props.shopId}',
          product_uuid: '${currentProductUuid}',
          type: 'embed', 
          querySelector: '#appointo'
          }
        }
      </script>
      <script type="text/javascript" src="${bundleUrl}"></script>`
      const floatingBtnSnippet = `<script type="text/javascript">
        window.appointo = {
          config:{
            shop_uuid: '${props.shopId}',
            product_uuid: '${currentProductUuid}',
            type: 'floatingButton', 
            button_styles: {color: "#3E3E3E", backgroundColor : "green"},
            button_text: "Book Now"
            }
        }
      </script>
      <script type="text/javascript" src="${bundleUrl}"></script>`
      const popupSnippet = `<script type="text/javascript">
       window.appointo = {
        config:{
          shop_uuid: '${props.shopId}',
          product_uuid: '${currentProductUuid}',
          type: 'popup', 
          querySelector: '#appointo'
          }
        }
      </script>
      <script type="text/javascript" src="${bundleUrl}"></script>`
      const shopifySnippet = `<div class="appointo-custom-btn" data-appointo-product-handle="${productHandle}" type="embed"></div>`
      if (userData.shop.flow === 'shopify') {
        setSnippet(shopifySnippet)
      } else {
        switch (snippetType) {
          case 1:
            setSnippet(embedCodeSnippet)
            break
          case 2:
            setSnippet(floatingBtnSnippet)
            break
          case 3:
            setSnippet(popupSnippet)
            break
        }
      }
    }
  }, [
    props.selectedProductId,
    props?.servicesList,
    snippetType,
    currentProductUuid,
  ])

  const CodeSnippet = () => (
    <pre
      className="text-sm"
      style={{
        color: 'rgb(197, 200, 198)',
        textShadow: 'rgba(0, 0, 0, 0.3) 0px 1px',
        fontFamily:
          'Inconsolata, Monaco, Consolas, "Courier New", Courier, monospace',
        direction: 'ltr',
        textAlign: 'left',
        whiteSpace: 'pre',
        wordSpacing: 'normal',
        wordBreak: 'normal',
        lineHeight: '1.5',
        tabSize: 4,
        hyphens: 'none',
        padding: '1em',
        margin: '0.5em 0px',
        overflow: 'auto',
        borderRadius: '0.3em',
        background: 'rgb(29, 31, 33)',
      }}
    >
      <code
        className="language-javascript"
        style={{
          color: 'rgb(197, 200, 198)',
          textShadow: 'rgba(0, 0, 0, 0.3) 0px 1px',
          fontFamily:
            'Inconsolata, Monaco, Consolas, "Courier New", Courier, monospace',
          direction: 'ltr',
          textAlign: 'left',
          whiteSpace: 'pre',
          wordSpacing: 'normal',
          wordBreak: 'normal',
          lineHeight: '1.5',
          tabSize: 4,
          hyphens: 'none',
        }}
      >
        <span className="token" style={{ color: 'rgb(237, 237, 237)' }}>
          &lt;
        </span>
        <span>script type</span>
        <span className="token" style={{ color: 'rgb(237, 237, 237)' }}>
          =
        </span>
        <span className="token" style={{ color: 'rgb(168, 255, 96)' }}>
          "text/javascript"
        </span>
        <span className="token" style={{ color: 'rgb(237, 237, 237)' }}>
          &gt;
        </span>
        <span>{'\n'}</span> window.appointo = {`{`}
        <span>{'\n\t'}</span> config: {`{\n`}
        {snippetType === 1 && (
          <span>{`\t\tshop_uuid: '${props.shopId}',\n\t\t product_uuid: '${currentProductUuid}',\n\t\t type: 'embed',\n\t\t querySelector: "#appointo"`}</span>
        )}
        {snippetType === 2 && (
          <span>{`\t\tshop_uuid: '${props.shopId}',\n\t\t product_uuid: '${currentProductUuid}',\n\t\t type: 'floatingButton',\n\t\t button_styles: {color: "#3E3E3E", backgroundColor : "green"},\n\t\t button_text: "Book Now"`}</span>
        )}
        {snippetType === 3 && (
          <span>{`\t\tshop_uuid: '${props.shopId}',\n\t\t product_uuid: '${currentProductUuid}',\n\t\t type: 'popup',\n\t\t querySelector: "#appointo"`}</span>
        )}
        <span>{'\n\t\t}\n'}</span>
        <span>{'\t}\n'}</span>
        <span className="token" style={{ color: 'rgb(237, 237, 237)' }}>
          &lt;
        </span>
        <span className="token" style={{ color: 'rgb(237, 237, 237)' }}>
          /
        </span>
        <span>script</span>
        <span className="token" style={{ color: 'rgb(237, 237, 237)' }}>
          &gt;
        </span>
        <span>{'\n'}</span>
        <span className="token" style={{ color: 'rgb(237, 237, 237)' }}>
          &lt;
        </span>
        <span>script type</span>
        <span className="token" style={{ color: 'rgb(237, 237, 237)' }}>
          =
        </span>
        <span className="token" style={{ color: 'rgb(168, 255, 96)' }}>
          "text/javascript"
        </span>
        <span> src</span>
        <span className="token" style={{ color: 'rgb(237, 237, 237)' }}>
          =
        </span>
        <span className="token" style={{ color: 'rgb(168, 255, 96)' }}>
          "{bundleUrl}"
        </span>
        <span className="token" style={{ color: 'rgb(237, 237, 237)' }}>
          &gt;
        </span>
        <span className="token" style={{ color: 'rgb(237, 237, 237)' }}>
          &lt;
        </span>
        <span className="token" style={{ color: 'rgb(237, 237, 237)' }}>
          /
        </span>
        <span>script</span>
        <span className="token" style={{ color: 'rgb(237, 237, 237)' }}>
          &gt;
        </span>
      </code>
    </pre>
  )

  const openDemo = () => {
    switch (snippetType) {
      case 1:
        window.open(
          `${getPublicUrl()}?type=embed&product_uuid=${currentProductUuid}`
        )
        break
      case 2:
        window.open(
          `${getPublicUrl()}?type=floatingButton&product_uuid=${currentProductUuid}`
        )
        break
      case 3:
        window.open(
          `${getPublicUrl()}?type=popup&product_uuid=${currentProductUuid}`
        )
        break
    }
  }

  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={props.handleClose}
        title={props.title}
        primaryButton={{
          title: props.btnTitle,
          onClick: copyEmbedCodeSnippet,
          loading: props.loading,
        }}
        secondaryButton={
          userData?.shop.flow !== 'shopify' && {
            title: 'Live Demo',
            onClick: openDemo,
          }
        }
      >
        {props.body}
        {userData.shop.flow === 'shopify' ? (
          <div className="bg-gray-200 p-5">
            <code>{snippet}</code>
          </div>
        ) : (
          <>
            <RadioButton
              containerClass="flex"
              optionsClass="w-30 h-30 mr-2 rounded-md"
              label="Select script to inject"
              selected={snippetType}
              setSelected={(value) => {
                setSnippetType(value)
              }}
              data={scriptTypes}
            />
            {snippetType && (
              <div className="p-2 mt-1 bg-gray-200 overflow-auto">
                <CodeSnippet />
              </div>
            )}
          </>
        )}
      </Modal>
    </div>
  )
}

export default EmbedCodeModal
