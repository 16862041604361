import Button from 'Components/Button'
import Heading from 'Components/Heading'
import Toast from 'Components/Toast'
import RemoveModal from 'Components/Modals/RemoveModal'
import Spinner from 'Components/Spinner'
import Table from 'Components/Table'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ServiceActions from 'Redux/ServiceRedux'
import { CREATE_SERVICE, EDIT_SERVICE, SERVICES } from 'Routes/routes'
import store from 'store'
import EmptyState from 'Components/EmptyState'
import AllCoursesModal from 'Components/Modals/AllCoursesModal'
import { TrackEvent, trailExpired } from 'Themes/constants'
import { CodeIcon, EyeIcon, TrashIcon } from '@heroicons/react/outline'
import EmbedCodeModal from 'Components/Modals/EmbedCodeModal'
import Tooltip from 'Components/Tooltip'

const Services = (props) => {
  const dispatch = useDispatch()
  const [showPaidToast, setShowPaidToast] = useState(false)
  const [showCopyToast, setShowCopyToast] = useState(false)
  const [services, setServices] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showCoursesModal, setShowCoursesModal] = useState(false)
  const [showEmbedModal, setShowEmbedModal] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [showBanner, setShowBanner] = useState(false)
  const [bannerStatus, setBannerStatus] = useState('')
  let thinkificSubdomain = store.get('thinkific_subdoamin')
  let userData = store.get('userData')
  const history = useHistory()
  const onCreate = () => {
    history.push(CREATE_SERVICE)
  }
  const [selectedProductId, setSelectedProductId] = useState()
  const servicesFetching = useSelector((state) => state.service.listFetching)
  const servicesDeleteFetching = useSelector(
    (state) => state.service.deleteFetching
  )
  const servicesList = useSelector((state) => state.service.list)
  const [canEdit, setCanEdit] = useState(
    userData.shop.flow === 'shopify'
      ? userData.team_member_setting.can_edit_appointment
      : true
  )

  useEffect(() => {
    dispatch(ServiceActions.servicesListRequest())

    if (window.location.href.includes('plan_purchase')) {
      if (
        window.location.href.includes('pro') ||
        window.location.href.includes('lifetime')
      ) {
        TrackEvent('Plan Upgraded Succesfully', {})
        setShowBanner(true)
        setBannerStatus('Plan upgraded successfully')
      } else if (window.location.href.includes('fail')) {
        TrackEvent('Plan Upgradation Cancelled', {})
        setShowBanner(true)
        setBannerStatus('Plan upgraded was cancelled')
      }
    }

    if (window.location.href.includes('app_sumo')) {
      setShowBanner(true)
      setBannerStatus('App Sumo Code redeemed succesfully')
    }
  }, [])

  const removeService = () => {
    dispatch(
      ServiceActions.servicesDeleteRequest(selectedProductId, () => {
        setShowModal(false)
        dispatch(ServiceActions.servicesListRequest())
        setShowToast(true)
      })
    )
  }

  const navigateToEditService = (product) => {
    if (thinkificSubdomain) {
      history.push(
        `${SERVICES}/${product.id}/appointments/${product.appointments[0].id}`
      )
    } else {
      history.push(`${EDIT_SERVICE}/${product.id}`)
    }
  }

  useEffect(() => {
    let s = servicesList.map((s) => {
      return [
        {
          value: (
            <Button
              className={`${
                canEdit ? 'hover:underline hover:text-blue-600' : ''
              } underline-offset-4 text-green-600`}
              title={s.product_name}
              onClick={() => {
                if (canEdit) {
                  navigateToEditService(s)
                } else {
                }
              }}
            />
          ),
        },
        {
          value: (
            <div className="flex flex-col">
              <p>Total variants : {s.variants_count}</p>
              {s.variants_count === 1
                ? `Duration: ${s.first_variant_duration}`
                : ''}
            </div>
          ),
        },
        {
          value: (
            <div className="inline-flex gap-2 items-center">
              {canEdit && (
                <Tooltip title="Embed Code">
                  <Button
                    type="defaultGray"
                    onClick={() => {
                      setShowEmbedModal(true)
                      setSelectedProductId(s.id)
                    }}
                    title={
                      <CodeIcon className="text-gray-600 w-5" width={20} />
                    }
                  />
                </Tooltip>
              )}
              {!thinkificSubdomain && (
                <Tooltip title="Preview">
                  <Button
                    type="defaultGray"
                    onClick={() => {
                      let userData = store.get('userData')
                      if (userData.shop.flow == 'shopify') {
                        window.open(
                          `https://${userData.shop.shopify_domain}/products/${s.handle}`,
                          '_blank'
                        )
                      } else {
                        window.open(
                          `${window.location.origin}/shop/${userData.shop.subdomain}/product/${s.internal_name}`
                        )
                      }
                    }}
                    title={<EyeIcon className="w-5" width={20} />}
                  />
                </Tooltip>
              )}

              {canEdit && (
                <Tooltip title="Delete product">
                  <Button
                    type="defaultGray"
                    onClick={() => {
                      setShowModal(true)
                      setSelectedProductId(s.id)
                    }}
                    title={
                      <TrashIcon className="text-red-600 w-5" width={20} />
                    }
                  />
                </Tooltip>
              )}
            </div>
          ),
          type: 'Button',
        },
      ]
    })
    setServices(s)
  }, [servicesList])

  const onAddCoures = () => {
    setShowCoursesModal(true)
  }

  // if (trailExpired()) {
  //   return (
  //     <EmptyState
  //       header="Trial Expired"
  //       subHeader="Please upgrade to conitnue using our services"
  //       btnTitle={'Upgrade Now'}
  //       onClick={() => {
  //         history.push('/dashboard/plans')
  //       }}
  //     />
  //   )
  // }

  return (
    <div>
      <Toast show={showBanner} setShow={setShowBanner} title={bannerStatus} />
      <Toast
        show={showPaidToast}
        setShow={setShowPaidToast}
        title="Please upgrade your plan to add more services"
        type="info"
      />
      <Toast
        show={showCopyToast}
        setShow={setShowCopyToast}
        title="Code copied successfully!"
        type="success"
      />
      <Toast
        show={showToast}
        setShow={setShowToast}
        title="Service has been deleted successfully"
      />
      <Heading
        title="Services"
        rightSection={
          <>
            {userData.shop.flow !== 'shopify' && (
              <Button
                title={!!thinkificSubdomain ? 'Add Courses' : '+ Create'}
                onClick={!!thinkificSubdomain ? onAddCoures : onCreate}
                type="primary"
              />
            )}
          </>
        }
      />

      {servicesFetching ? (
        <Spinner />
      ) : services.length === 0 ? (
        <>
          {userData.shop.flow === 'shopify' ? (
            <EmptyState
              header="No Services"
              subHeader="Please ask admin to add services"
            />
          ) : (
            <EmptyState
              header="No Services"
              subHeader="Please click below to add services"
              btnTitle={!!thinkificSubdomain ? 'Add Courses' : '+ Create'}
              onClick={!!thinkificSubdomain ? onAddCoures : onCreate}
            />
          )}
        </>
      ) : (
        <Table
          headers={['Service Name', 'Variant Info', '']}
          data={services}
          containerClass="my-6"
        />
      )}

      {showEmbedModal && (
        <EmbedCodeModal
          showModal={showEmbedModal}
          setShowModal={setShowEmbedModal}
          handleClose={() => {
            setShowEmbedModal(false)
          }}
          selectedProductId={selectedProductId}
          servicesList={servicesList}
          title="Embed Code"
          btnTitle="Copy"
          body="Use this code to embed this product on any page of your website"
          onClick={() => {
            setShowEmbedModal(false)
            setShowCopyToast(true)
          }}
          shopId={userData.shop.shop_uuid}
        />
      )}

      <RemoveModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleClose={() => {
          setShowModal(false)
        }}
        title="Are you sure?"
        btnTitle="Remove Service"
        body="Are you sure you want to remove this service? This action cannot be undone"
        onClick={removeService}
        loading={servicesDeleteFetching}
      />

      <AllCoursesModal
        showModal={showCoursesModal}
        setShowModal={setShowCoursesModal}
        title={'Select Courses'}
      />
    </div>
  )
}

export default Services
