import React, { useState, useEffect } from 'react'
import {
  Calendar as ReactCalendar,
  Views,
  momentLocalizer,
} from 'react-big-calendar'
import moment from 'moment'
import BookingActions from 'Redux/BookingRedux'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'Components/Spinner'
import Select from 'react-select'
import Label from 'Components/Label'
import { calendarColors } from '../../Themes/constants'
import chroma from 'chroma-js'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import Container from 'Components/Container'
import Heading from 'Components/Heading'
import { MANAGE_BOOKING } from 'Routes/routes'
import { useHistory } from 'react-router'
import store from 'store'
import Button from 'Components/Button'
import ManualBookingView from 'Components/Bookings/ManualBooking/ManualBookingView'

const localizer = momentLocalizer(moment)
let allViews = Object.keys(Views).map((k) => Views[k])

const CalendarView = (props) => {
  const history = useHistory()
  const [teamOptions, setTeamOptions] = useState([])
  const [bookings, setBookings] = useState([])
  const bookingSelected = (event) => {
    let booking = bookingsList.find((b) => b.id === event.id)
    if (booking && booking.event_type === 'appointo') {
      history.push(`${MANAGE_BOOKING}/${event.id}`)
    }
  }
  const dispatch = useDispatch()
  const teamList = useSelector((state) => state.team.list)
  const bookingsFetching = useSelector(
    (state) => state.booking.calendarListFetching
  )
  const [selectedType, setSelectedType] = useState('all')
  const [teamMembers, setTeamMembers] = useState([])
  const settingsData = store.get('userData')

  const [showManualModal, setShowManualModal] = useState(false)

  useEffect(() => {
    if (settingsData && settingsData.role === 'admin') {
      dispatch(
        BookingActions.bookingsCalendarListRequest({
          ...range,
          type: selectedType,
          tms: `[${teamMembers.join(',')}]`,
        })
      )
    } else {
      dispatch(
        BookingActions.bookingsCalendarListRequest({
          ...range,
          type: 'selected_tms',
          tms: `[${settingsData.id}]`,
        })
      )
    }
  }, [])

  const [bgView, setBgView] = useState(Views.WEEK)
  const [date, setDate] = useState(new Date())
  const [range, setRange] = useState({
    start_date: moment().startOf('week').format('YYYY-MM-DD'),
    end_date: moment().endOf('week').format('YYYY-MM-DD'),
  })

  const bookingsList = useSelector((state) => state.booking.calendarList)

  useEffect(() => {
    setBookings(formatBookingsList(bookingsList))
  }, [bookingsList])

  const onRangeChange = (value) => {
    let range = {}
    if (value.hasOwnProperty('start')) {
      range = {
        start_date: moment(value.start).format('YYYY-MM-DD'),
        end_date: moment(value.end).format('YYYY-MM-DD'),
      }
    } else {
      range = {
        start_date: moment(value[0]).format('YYYY-MM-DD'),
        end_date: moment(value[value.length - 1]).format('YYYY-MM-DD'),
      }
    }
    setRange(range)
    dispatch(
      BookingActions.bookingsCalendarListRequest({
        ...range,
        type: selectedType,
        tms: `[${teamMembers.join(',')}]`,
      })
    )
  }

  const formatBookingsList = (bookingsList) => {
    return [
      ...bookingsList.map((b) => {
        let startTime = new Date(b.start_time)

        let monthData =
          bgView === Views.MONTH
            ? `${moment(b.start_time).format('HH:mm')}-${moment(
                b.end_time
              ).format('HH:mm')} `
            : ''

        let extraTitleData = ''
        if (b.max_capacity > 1) {
          extraTitleData = `${b.customers_count}/${b.max_capacity} - `
        }

        let title = `${monthData}${extraTitleData}${b.title}`

        return {
          id: b.id,
          title: title,
          start: startTime,
          end: new Date(b.end_time),
        }
      }),
    ]
  }

  useEffect(() => {
    if (props.isWeb) {
      setTeamOptions([{ label: '', value: null }])
    } else {
      let list = teamList
        .filter((t) => t.confirmed)
        .map((t) => {
          return {
            label: t.email,
            value: t.id,
          }
        })
      setTeamOptions([{ label: '', value: null }, ...list])
    }
  }, [teamList])

  const onChange = (option) => {
    if (!!option) {
      dispatch(
        BookingActions.bookingsCalendarListRequest({
          ...range,
          type: 'selected_tms',
          tms: `[${option.map((o) => o.value).join(',')}]`,
        })
      )
      setSelectedType('selected_tms')
      setTeamMembers(option.map((o) => o.value))
    } else {
      setTeamMembers([])
      dispatch(
        BookingActions.bookingsCalendarListRequest({
          ...props.range,
          type: 'all',
          tms: [],
        })
      )
      setSelectedType('all')
    }
  }

  const getColorIndex = (event) => {
    let index = 0
    let booking = bookingsList.find((b) => b.id === event.id)
    if (booking && booking.team_member_id) {
      index = getTeamMemberIndex(booking.team_member_id)
    }
    return index
  }

  const getTeamMemberIndex = (team_member_id) => {
    let index = 1
    let teamListData = teamList.find((t) => `${t.id}` === `${team_member_id}`)
    index = teamList.indexOf(teamListData) + 1
    if (index > calendarColors.length) {
      index = Math.round(index / calendarColors.length)
    }
    if (index < 0 || index >= calendarColors.length) {
      index = 1
    }
    return index
  }

  const eventStyleGetter = (event, start, end, isSelected) => {
    let index = getColorIndex(event)
    let eventFinished = false
    if (moment().isAfter(moment(start))) {
      eventFinished = true
    }

    var backgroundColor = calendarColors[index]
    var style = {
      backgroundColor: backgroundColor,
      opacity: eventFinished ? 0.4 : 1,
      fontSize: 11,
    }
    return {
      style: style,
    }
  }

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let index = getTeamMemberIndex(data.value)
      let calendarColor = calendarColors[index]
      const color = chroma(calendarColor)
      return {
        ...styles,

        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : calendarColor,
      }
    },
    multiValue: (styles, { data }) => {
      let index = getTeamMemberIndex(data.value)
      let calendarColor = calendarColors[index]
      const color = chroma(calendarColor)
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      }
    },
    multiValueLabel: (styles, { data }) => {
      let index = getTeamMemberIndex(data.value)
      let calendarColor = calendarColors[index]
      console.log(index)
      return {
        ...styles,
        color: calendarColor,
      }
    },
    multiValueRemove: (styles, { data }) => {
      let index = getTeamMemberIndex(data.value)
      let calendarColor = calendarColors[index]
      return {
        ...styles,
        color: calendarColor,
        ':hover': {
          backgroundColor: calendarColor,
          color: 'white',
        },
      }
    },
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    container: (provided) => ({
      ...provided,
      marginTop: '10px',
    }),
  }

  return (
    <div>
      {!showManualModal && (
        <Heading
          title="Calendar"
          rightSection={
            <>
              <Button
                title="+Manual Booking"
                type="primary"
                onClick={() => setShowManualModal(true)}
              />
            </>
          }
        />
      )}
      {showManualModal ? (
        <>
          <ManualBookingView
            showModal={showManualModal}
            handleClose={(success) => {
              setShowManualModal(false)
              history.push('/dashboard/bookings')
            }}
            setShowManualModal={setShowManualModal}
            user={props.user}
            shop={props.shop}
          />
        </>
      ) : (
        <>
          {bookingsFetching ? (
            <Spinner />
          ) : (
            <>
              {settingsData.role === 'admin' && teamList.length > 0 && (
                <div
                  style={{
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                >
                  <Label>Select Team Members</Label>
                  <Select
                    label=""
                    isMulti
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable
                    options={teamOptions}
                    onChange={onChange}
                    value={teamOptions.filter(
                      (s) => !!teamMembers.find((t) => t === s.value)
                    )}
                    menuPortalTarget={document.body}
                    styles={colourStyles}
                  />
                </div>
              )}

              <Container containerClass="my-6">
                <ReactCalendar
                  selectable
                  popup
                  localizer={localizer}
                  events={bookings}
                  startAccessor="start"
                  endAccessor="end"
                  date={date}
                  views={allViews}
                  showMultiDayTimes
                  view={bgView}
                  onSelectEvent={bookingSelected}
                  style={{ height: 600, width: '95%' }}
                  onView={(value) => {
                    setBgView(value)
                  }}
                  onNavigate={(value) => {
                    setDate(value)
                  }}
                  onRangeChange={onRangeChange}
                  step={15}
                  eventPropGetter={eventStyleGetter}
                  min={new Date(0, 0, 0, 6, 0, 0)}
                />
              </Container>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default CalendarView
