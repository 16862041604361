import React, { useState, useCallback, useEffect } from 'react'
import Sidebar from 'Components/Sidebar'
import Services from 'Containers/Services/Services'
import Bookings from 'Containers/Bookings/Bookings'
import Settings from 'Containers/Settings/Settings'
import Integrations from 'Containers/Integrations/Integrations'
import Teams from 'Containers/Teams/Teams'
import { Route, Switch, Redirect } from 'react-router-dom'
import {
  BOOKINGS,
  CALENDAR,
  CONFLICT,
  CREATE_SERVICE,
  CUSTOMERS,
  DASHBOARD,
  MANAGE_CUSTOMER,
  ONBOARDING,
  PLANS,
  SERVICES,
  SETTINGS,
  SUBSCRIPTIONS,
  TEAMS,
  THEMES,
  TRANSLATIONS,
  WAITLIST,
  WORKFLOWS,
} from 'Routes/routes'
import AddEditService from 'Containers/Services/AddEditService/AddEditService'
import { EDIT_SERVICE, INTEGRATIONS, MANAGE_BOOKING } from '../Routes/routes'
import ManageBooking from './Bookings/ManageBooking/ManageBooking'
import EditVariant from './Services/EditVariant'
import Login from './Login'
import StartupActions from 'Redux/StartupRedux'
import { useDispatch } from 'react-redux'
import Signup from './Signup'
import store from 'store'
import ForgotPassword from './ForgotPassword'
import PublicUrl from './PublicUrl'
import Wix from './Wix'
import AdminLogin from './AdminLogin'
import Plans from './Plans/Plans'
import Translations from './Translations/Translations'
import AuthAction from 'Redux/AuthRedux'
import Redirected from './Redirected'
import CalendarView from './Calendar/Calendar'
import Workflows from './Workflows/Workflows'
import ManageWorkflow from './Workflows/ManageWorkflow'
import AppSumoSignup from './AppSumoSignup'
import Banner from 'Components/Banner'
import ChatTool from 'Components/ChatTool'
import Themes from 'Containers/Themes/Themes'
import SmartlookClient from 'Components/SmartlookClient'
import Onboarding from './Onboarding/Onboarding'
import { trailExpired } from 'Themes/constants'
import posthog from 'posthog-js'
import Customer from 'Components/Bookings/Customers/Customer'
import CustomerDetails from 'Components/Bookings/Customers/CustomerDetails'
import Subscription from 'Components/Bookings/Subscriptions/Subscription'
import Waitlist from 'Components/Bookings/Waitlist/Waitlist'
import Conflicts from 'Components/Bookings/Conflicts/Conflicts'

const Main = (props) => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const [showBanner, setShowBanner] = useState(false)
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const masqueradeUuid = queryParameters.get('masquerade_uuid')
    const teamMemberEmail = queryParameters.get('team_member_email')
    const teamMemberId = queryParameters.get('team_member_id')

    if (!masqueradeUuid) {
      dispatch(
        StartupActions.startup((success) => {
          if (success) {
            dispatch(
              AuthAction.meRequest(() => {
                let settingsData = store.get('userData')
                posthog.identify(settingsData.shop.owner_email)
                setTimeout(() => {
                  if (trailExpired()) {
                    setShowBanner(true)
                  }
                }, 1000)
              })
            )
          }
          setLoading(false)
        })
      )
    } else {
      dispatch(
        AuthAction.masqueradeRequest(
          {
            masquerade_uuid: masqueradeUuid,
            team_member_email: teamMemberEmail,
            team_member_id: teamMemberId,
          },
          () => {
            dispatch(AuthAction.meRequest(() => {}))
            setLoading(false)
          }
        )
      )
    }
  }, [])

  const authData = store.get('authData')

  return (
    <>
      {!loading && (
        <Switch>
          {/* AUTH ROUTES */}

          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Signup} />
          <Route exact path="/appsumo/:id" component={AppSumoSignup} />
          <Route exact path="/forgot-password" component={ForgotPassword} />

          {/* PUBLIC URL ROUTES */}
          <Route exact path="/appointments/:id" component={PublicUrl} />
          <Route
            exact
            path="/appointments/:id/confirm_booking/:customerId"
            component={PublicUrl}
          />
          <Route
            exact
            path="/appointments/:id/product/:productId"
            component={PublicUrl}
          />
          {/* PUBLIC URL v2 ROUTES */}
          <Route exact path="/shop/:id" component={PublicUrl} />
          <Route
            exact
            path="/shop/:id/product/:productId"
            component={PublicUrl}
          />
          <Route
            exact
            path="/shop/:id/confirm_booking/:customerId"
            component={PublicUrl}
          />
          {/* OTHER ROUTES */}
          <Route exact path="/wix" component={Wix} />
          <Route exact path="/admin_login" component={AdminLogin} />
          <Route exact path="/redirected" component={Redirected} />

          {!!authData && !!authData['access-token'] ? (
            <>
              {showBanner && <Banner />}

              <ChatTool />
              <SmartlookClient />
              <Sidebar>
                {/* SERVICES ROUTES */}
                <Route exact path="/" component={Services} />
                <Route exact path={DASHBOARD} component={Services} />
                <Route exact path={ONBOARDING} component={Onboarding} />
                <Route exact path={CREATE_SERVICE} component={AddEditService} />
                <Route
                  path={`${EDIT_SERVICE}/:id`}
                  component={AddEditService}
                />
                <Route
                  path={`${SERVICES}/:productId/appointments/:id`}
                  component={EditVariant}
                />

                {/* BOOKINGS ROUTES */}
                <Route exact path={BOOKINGS} component={Bookings} />
                <Route
                  path={`${MANAGE_BOOKING}/:id`}
                  component={ManageBooking}
                />

                {/* SUBSCRIPTION ROUTES */}
                <Route exact path={SUBSCRIPTIONS} component={Subscription} />

                {/* CUSTOMER ROUTES */}
                <Route exact path={CUSTOMERS} component={Customer} />
                <Route
                  path={`${MANAGE_CUSTOMER}`}
                  component={CustomerDetails}
                />

                {/* WAITLIST ROUTES */}
                <Route exact path={WAITLIST} component={Waitlist} />

                {/* CONFLICT ROUTES */}
                <Route path={CONFLICT} exact component={Conflicts} />

                {/* CALENDAR ROUTES */}
                <Route exact path={CALENDAR} component={CalendarView} />

                {/* INTEGRATIONS ROUTES */}
                <Route exact path={INTEGRATIONS} component={Integrations} />

                {/* WORKFLOWS ROUTES */}
                <Route exact path={WORKFLOWS} component={Workflows} />
                <Route path={`${WORKFLOWS}/:id`} component={ManageWorkflow} />

                {/* SETTINGS ROUTES */}
                <Route exact path={SETTINGS} component={Settings} />

                {/* TEAMS ROUTES */}
                <Route exact path={TEAMS} component={Teams} />

                {/* THEMES ROUTES */}
                <Route exact path={THEMES} component={Themes} />

                {/* TRANSLATIONS ROUTES */}
                <Route exact path={TRANSLATIONS} component={Translations} />

                {/* PLANS ROUTES */}
                <Route exact path={PLANS} component={Plans} />
              </Sidebar>
            </>
          ) : (
            <Route
              render={(props) => {
                let pathname =
                  window.location.href.indexOf('register') !== -1
                    ? '/register'
                    : '/login'

                if (
                  window.location.href.includes('call.sidepanda.com') &&
                  window.location.href !==
                    'https://call.sidepanda.com/shop/appointo-main/product/7f6d2d5ff608958cffb55229'
                ) {
                  pathname =
                    '/shop/appointo-main/product/7f6d2d5ff608958cffb55229'
                }

                return <Redirect to={{ pathname: pathname }} />
              }}
            />
          )}
        </Switch>
      )}
    </>
  )
}

export default Main
