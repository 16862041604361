import apisauce from 'apisauce'

export const authDataKeys = ['access-token', 'client', 'token-type', 'uid']

export default {
  create: () => {
    const api = apisauce.create({
      baseURL: `${process.env.REACT_APP_BASE_URL}/dashboard`,
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 30000,
    })

    const setAuthData = (authData) => {
      return api.setHeaders(authData)
    }

    const deleteAuthData = () => {
      return authDataKeys.map((key) => {
        return api.deleteHeader(authDataKeys)
      })
    }

    return {
      setAuthData,
      deleteAuthData,
      meRequest: (params) => api.get('dashboard/me', params),
      masqueradeRequest: (params) => api.get('scripttag/masqeurade', params),
      getServices: (params) => api.get(`products`, params),
      upsertServices: (params) => api.put(`products/upsert`, params),
      getServiceDetails: (id) => api.get(`products/${id}`),
      getVariantDetails: (id, productId) =>
        api.get(`products/${productId}/appointments/${id}`),
      upsertVariant: (params) =>
        api.put(
          `products/${params.productId}/appointments/single_upsert`,
          params
        ),
      deleteCustomField: (variantId, productId, params) =>
        api.delete(
          `products/${productId}/appointments/${variantId}/delete_custom_field`,
          params
        ),
      login: (params) => api.post(`auth/sign_in`, params),
      signup: (params) => api.post(`signup`, params),
      getBookings: (params) => api.get(`appointments/bookings`, params),
      getBookingDetails: (params) => api.get(`appointments/bookings`, params),
      cancelBooking: (params) =>
        api.delete(`appointments/cancel_booking`, params),
      rescheduleBooking: (params) =>
        api.put(`appointments/reschedule_booking`, params),
      googleLogin: (params) => api.get(`google_login/login`, params),
      wixLogin: (queryString) => api.get(`wix_login/login${queryString}`),
      adminLogin: (queryString) => api.get(`admin_login/login${queryString}`),
      redirectedLogin: (queryString) =>
        api.get(`redirected_login${queryString}`),
      cancelBooking: (params) =>
        api.delete(`appointments/cancel_booking`, params),
      forgotPassword: (params) => api.post(`auth/password`, params),
      updateSettings: (params) => api.put(`settings/shop_setting`, params),
      deleteService: (productId) => api.delete(`products/${productId}`),
      deleteVariant: (productId, apptId) =>
        api.delete(`products/${productId}/appointments/${apptId}`),
      getTeams: () => api.get(`teams`),
      createTeam: (params) => api.post(`teams`, params),
      deleteTeam: (id) => api.delete(`teams/${id}`),
      updateTeamMember: (id, params) => api.put(`teams/${id}`, params),
      getValidTeamMemberForBooking: (params) =>
        api.get(`/appointments/valid_tms_for_reassign`, params),
      reassignTeamMember: (params) =>
        api.put(`appointments/reassign_team_member_to_booking`, params),
      sendMemberVerificationEmail: (id) =>
        api.put(`teams/${id}/send_confirmation`),
      upgradePlan: (params) => api.get(`stripe/initiate`, params),
      cancelPlan: () => api.delete(`stripe/delete_subscription`),
      connectGoogleCalendar: (params) =>
        api.post(`settings/google_calendar_auth`, params),
      removeGoogleCalendar: () => api.delete(`settings/disconnect_google`),
      getCalendarBookings: (params) =>
        api.get(`appointments/calendar_bookings`, params),
      getWorkflows: () => api.get(`workflows`),
      createWorkflow: (params) => api.put(`workflows/upsert`, params),
      deleteWorkflow: (id) => api.delete(`workflows/${id}`),
      updateWorkflow: (id, params) => api.put(`workflows/upsert`, params),
      getWorkflowTypes: () => api.get(`workflows/types`),
      getWorkflowDetails: (id) => api.get(`workflows/${id}`),
      searchVariants: (params) => api.get(`/appointments/search`, params),
      getThinificCourses: (params) => api.get(`courses`, params),
      upsertThinkificCourses: (params) =>
        api.post(`courses/batch_create`, params),
      removeZoom: (_) => api.delete(`settings/disconnect_zoom`),
      updateTextSetting: (params) =>
        api.put(`text_settings/${params.id}`, params),
      getThemeFiles: () => api.get(`settings/theme`),
      getThemeFileDetails: (id) =>
        api.get(`settings/theme?theme_file_id=${id}`),
      updateThemeFile: (params) =>
        api.put(`settings/update_theme_file`, params),
      refundAmount: (params) => api.put(`appointments/refund_booking`, params),
      getCancelledBookings: (params) =>
        api.get(`appointments/cancelled_bookings`, params),
      getCancelledBookingDetails: (params) =>
        api.get(`appointments/cancelled_bookings`, params),
      onGenerateZapierApiToken: () =>
        api.put(`settings/generate_api_zapier_token`),
      onGenerateApiToken: () => api.put(`settings/generate_api_token`),
      disconnectStripeAccount: () =>
        api.put(`settings/disconnect_stripe_account`),
      connectAppleCalendar: (params) =>
        api.post(`settings/apple_calendar_auth`, params),
      disconnectAppleCalendarRequest: (params) =>
        api.delete(`settings/disconnect_apple_calendar`, params),
      conflictsRequest: (params) => api.get(`appointments/conflicts`, params),
      getCustomers: (params) => api.get('customers/list', params),
      getWaitlist: (params) => api.get(`waitlist`, params),
      getSubscriptions: (params) =>
        api.get(`/appointment_subscriptions`, params),
      deleteConflict: (id) =>
        api.delete(`appointments/delete_conflict`, { id }),
      getCustomerDetails: (params) =>
        api.get(`customers/customer_info`, params),
      updateAllCustomer: (params) =>
        api.put(`customers/update_cust_by_email_and_name`, params),
      getCustomerMessages: (params) => api.get(`customers/messages`, params),
      markAsRead: (params) => api.put(`customers/mark_sms_as_read`, params),
      getBookingSubscription: (id) =>
        api.get(`appointment_subscriptions/${id}`),
      updateSubscriptionDetails: (params) =>
        api.put(`appointment_subscriptions/${params.id}`, params),
      deleteSubscription: (id) => api.delete(`appointment_subscriptions/${id}`),
      bookingsByCustomer: (params) =>
        api.get(`appointment_subscriptions/unconnected_customers`, params),
      connectBookingToSubscription: (params) =>
        api.put(`appointment_subscriptions/connect_booking`, params),
      getWaitlists: (params) => api.get(`waitlist`, params),
      upsertWaitlist: (params) => api.put(`waitlist/${params.id}`, params),
      editBooking: (id, params) => api.put(`bookings/${id}`, params),
      restoreBooking: (id, params) => api.put(`bookings/${id}/restore`, params),
      createNote: (id, params) => api.put(`bookings/${id}/upsert_note`, params),
      deleteNote: (id, params) =>
        api.delete(`bookings/${id}/delete_note`, params),
      getSubscriptionPlans: () => api.get('selling_plan_groups'),
      updateTeamMemberSetting: (params) =>
        api.put(`settings/member_setting`, params),
      generateBookingLink: (params) =>
        api.post(`appointments/create_booking`, params),
      updateCustomer: (id, params) => api.put(`customers/${id}`, params),
      createManualSubscription: (params) =>
        api.post(`/appointment_subscriptions`, params),
    }
  },
}
