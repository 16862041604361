import React, { useState, useCallback, useEffect, useRef } from 'react'
import Input from 'Components/Input'
import Checkbox from 'Components/Checkbox'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import styles from 'Styles/RescheduleBooking.module.css'
import Litepicker from 'litepicker'
import { getStats } from 'Themes/constants'
import Spinner from 'Components/Spinner'
import store from 'store'

const SelectTime = (props) => {
  const dispatch = useDispatch()
  const [availFetching, setAvailFetching] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState({})
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })
  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  )
  const userData = store.get('userData')

  const [lPicker, setLPicker] = useState({})
  const [override, setOverride] = useState(false)
  const [slots, setSlots] = useState([])
  const [calendlyDays, setCalendlyDays] = useState([])
  const [timezone, setTimeZone] = useState()
  const [lockedDays, setLockedDays] = useState([])
  const [minDays, setMinDays] = useState('')
  const [maxDays, setMaxDays] = useState('')
  const [maxCapacity, setMaxCapacity] = useState(1)

  const [selectedDate, setSelectedDate] = useState()
  const [selectedEndDate, setSelectedEndDate] = useState(
    moment().format(`YYYY-MM-DD`)
  )
  const isMultiDay =
    props.selectedProduct && props.selectedProduct.scenario === 'multi_day'
  const overrideRef = useRef(override)
  overrideRef.current = override

  useEffect(() => {
    if (props.variantId) {
      if (!!lPicker && !!lPicker.destroy) {
        lPicker.destroy()
      }
      let picker1 = new Litepicker({
        element: document.getElementById('litepicker'),
        // minDate: moment(),
        inlineMode: true,
        firstDay: 0,
        singleMode: !isMultiDay,
        setup: (picker) => {
          picker.on('change:month', (date, calendarIdx) => {
            if (
              moment(date.dateInstance).isBefore(new Date()) &&
              !overrideRef.current
            ) {
              picker.setDate(new Date())
              onChange(moment().format(`YYYY-MM-DD`), picker)
            } else {
              if (isMultiDay) {
                picker.setDateRange(date, date, true)
              } else {
                picker.setDate(date)
              }
              onChange(moment(date.dateInstance).format(`YYYY-MM-DD`), picker)
            }
          })

          picker.on('selected', (date1, date2) => {
            if (isMultiDay) {
              if (!!date2) {
                setSelectedEndDate(
                  moment(date2.dateInstance).format(`YYYY-MM-DD`)
                )
                props.setSelectedEndDate(
                  moment(date2.dateInstance).format(`YYYY-MM-DD`)
                )
              } else {
                setSelectedEndDate(
                  moment(date1.dateInstance).format(`YYYY-MM-DD`)
                )
                props.setSelectedEndDate(
                  moment(date1.dateInstance).format(`YYYY-MM-DD`)
                )
              }

              props.setSelectedDate(
                moment(date1.dateInstance).format(`YYYY-MM-DD`)
              )
            } else {
              onChange(moment(date1.dateInstance).format(`YYYY-MM-DD`), picker)
            }
          })
        },
      })
      setAvailData(
        props.variantId,
        new Date(),
        true,
        picker1,
        overrideRef.current
      )
      setLPicker(picker1)
    }
  }, [props.variantId, props.tms])

  const setAvailData = async (
    durationUuid,
    selectedDate,
    forceFetch = false,
    picker,
    overrideAvailbility
  ) => {
    let day = moment(selectedDate).format('YYYY-MM-DD')
    const shouldFetch =
      !!calendlyDays && !!calendlyDays.calendly_events
        ? calendlyDays.calendly_events.days.find((d) => d.date === day)
        : false

    if (!shouldFetch || forceFetch) {
      let impersonated_tm = ''
      if (props.isWeb) {
        impersonated_tm = `&impersonated_tm=${props.user.id}`
      } else if (props.tms) {
        impersonated_tm = `&impersonated_tm=${props.tms}`
      }

      let soM = moment(selectedDate).startOf('month').format('YYYY-MM-DD')
      let eoM = moment(selectedDate).endOf('month').format('YYYY-MM-DD')

      setAvailFetching(true)
      let appointo_calendly_data = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }/scripttag/calendar_availability?start_date=${soM}&end_date=${eoM}&duration_uuid=${durationUuid.replace(
          'gid://shopify/ProductVariant/',
          ''
        )}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}
        `
      )
      setAvailFetching(false)

      if (
        !!appointo_calendly_data.data &&
        !!appointo_calendly_data.data.calendly_events
      ) {
        setCalendlyDays(appointo_calendly_data.data)

        let day_events = appointo_calendly_data.data.calendly_events.days.find(
          (d) => d.date === day
        ) || { spots: [] }
        setSlots(
          day_events.spots.filter(
            (s) => overrideAvailbility || s.is_available || s.waitlist_available
          )
        )
        setTimeZone(
          appointo_calendly_data.data.calendly_events.availability_timezone
        )
      }
      let days = []
      appointo_calendly_data.data.calendly_events.days
        .filter((d) => d.status !== 'available')
        .map((d) => {
          days.push(d.date)
        })

      setMaxCapacity(
        appointo_calendly_data.data.appointment_config.max_capacity
      )

      if (isMultiDay) {
        let minDay =
          appointo_calendly_data.data.appointment_config.min_day_duration
        let maxDay =
          appointo_calendly_data.data.appointment_config.max_day_duration
        picker.setOptions({
          lockDays: days,
          maxDays: maxDay,
          minDays: minDay,
        })

        setMinDays(minDay)
        setMaxDays(maxDay)
      } else {
        picker.setOptions({
          lockDays: days,
        })
      }
    } else {
      let day_events = calendlyDays.calendly_events.days.find(
        (d) => d.date === day
      ) || { spots: [] }
      setSlots(
        day_events.spots.filter(
          (s) => overrideAvailbility || s.is_available || s.waitlist_available
        )
      )
    }
  }

  const slotClicked = (slot) => () => {
    setSelectedSlot(slot)
    if (!!props.setSelectedSlot) {
      props.setSelectedSlot(slot)
    }

    props.setSelectedDate(slot.start_time)
    if (props.setTms) {
      props.setTms(slot.team_members)
    }
  }

  const onChange = (selectedDates, picker) => {
    setAvailData(
      props.variantId,
      selectedDates,
      true,
      picker,
      overrideRef.current
    )
    setSelectedDate(selectedDates)
  }

  return (
    <div className={styles.container}>
      {parseInt(maxCapacity) > 1 &&
        !props.customerPortal &&
        !props.subscription && (
          <div
            className={styles.checkbox}
            style={props.fromView ? { marginLeft: 0 } : {}}
          >
            <Input
              label="Quantity"
              onChange={(value) => props.setQuantity(value)}
              value={props.quantity}
            />
          </div>
        )}

      <div
        className={`${styles.calendarContianer} w-full flex flex-col gap-2 md:flex-row`}
      >
        <div
          id="litepicker"
          className={`${styles.datePickerContainer2} w-full`}
          style={{
            width: '100%',
          }}
        ></div>
        {!isMultiDay && (
          <div className={styles.eventsContainer2}>
            <p className={styles.dateSelected}>
              {moment(selectedDate).format('dddd, MMM DD')} - Available Slots
            </p>
            {availFetching ? (
              <Spinner />
            ) : (
              <div
                className={styles.calendarSlots}
                style={slots.length > 0 ? { overflowY: 'scroll' } : {}}
              >
                {slots.length ? (
                  slots.map((slot, i) => {
                    return (
                      <>
                        <div
                          key={`appointo-slot-${i}`}
                          className={styles.timeContainer}
                        >
                          <div
                            className={`${styles.slot} ${
                              selectedSlot.start_time === slot.start_time
                                ? styles.selectedSlot
                                : ''
                            }`}
                            onClick={slotClicked(slot)}
                          >
                            {moment(slot.start_time).format('hh:mm A')}
                            {!slot.is_available && slot.waitlist_available
                              ? '(Waitlist slot)'
                              : ''}
                          </div>
                        </div>
                        {getStats(slot)}
                      </>
                    )
                  })
                ) : (
                  <div className={styles.noSlots}>
                    Uh-Oh. All time slots have been filled for the day. Please
                    choose another date
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {isMultiDay && !!minDays && !!maxDays && (
          <div className={styles.eventsContainer2}>
            <p className={styles.dateSelected}>Selection Rules</p>
            <p className={styles.dateSelected}>Minimum days: {minDays} </p>
            <p className={styles.dateSelected}>Maximum days: {maxDays} </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default SelectTime
