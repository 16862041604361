import React, { useEffect, useRef } from 'react'
import Litepicker from 'litepicker'
import 'litepicker/dist/css/litepicker.css'

const DatePicker = ({
  selected,
  onChange,
  nextMonthButtonLabel,
  previousMonthButtonLabel,
  inline = true,
}) => {
  const pickerRef = useRef(null)

  useEffect(() => {
    const picker = new Litepicker({
      element: pickerRef.current,
      startDate: selected,
      inlineMode: inline,
      buttonText: {
        nextMonth: nextMonthButtonLabel,
        previousMonth: previousMonthButtonLabel,
      },
      setup: (picker) => {
        picker.on('selected', (date1) => {
          onChange(date1.dateInstance)
        })
      },
    })

    return () => picker.destroy()
  }, [
    selected,
    onChange,
    nextMonthButtonLabel,
    previousMonthButtonLabel,
    inline,
  ])

  return <div id="litepicker" ref={pickerRef}></div>
}

export default DatePicker
