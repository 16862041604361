import Button from 'Components/Button'
import CardHeading from 'Components/CardHeading'
import Container from 'Components/Container'
import Spinner from 'Components/Spinner'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import BookingActions from 'Redux/BookingRedux'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import RescheduleBookingDetails from 'Components/Modals/RescheduleBookingModal'
import RefundAmountModal from 'Components/Modals/RefundAmountModal'
import { AWS_URL, CANCELLED_SCENARIO, TrackEvent } from 'Themes/constants'
import {
  BadgeCheckIcon,
  CalendarIcon,
  LinkIcon,
  PencilAltIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import Table from 'Components/Table'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import ViewQuestionsModal from 'Components/Modals/ViewQuestionsModal'
import ViewBillingInfo from 'Components/Modals/ViewBillingInfo'
import EditDurationModal from 'Components/Modals/EditDurationModal'
import ShowCountModal from 'Components/Modals/ShowCountModal'
import Card from 'Components/Card'
import NoteModal from 'Components/Modals/NoteModal'
import store from 'store'
import Select from 'Components/Select'
import CardSection from 'Components/CardSection'
import Tooltip from 'Components/Tooltip'
import ConfirmationModal from 'Components/ConfirmationModal'
import NavigationActions from 'Redux/NavigationRedux'

const ManageBooking = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [customer, setCustomer] = useState({})
  const [showRefundModal, setShowRefundModal] = useState(false)
  const [customerTableData, setCustomerTableData] = useState([])
  const [showCfModal, setShowCfModal] = useState(false)
  const [showBillingInfo, setShowBillingInfo] = useState(false)
  const [showEditDurationModal, setShowEditDurationModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showCountModal, setShowCountModal] = useState(false)
  const [selectedType, setSelectedType] = useState('')
  const [currentCustomers, setCurrentCustomers] = useState([])
  const [showNoteModal, setShowNoteModal] = useState(false)
  const [statusOptions, setStatusOptions] = useState([])
  const [statusIndex, setStatusIndex] = useState(-1)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [cancelLoading, setCancelLoading] = useState(false)

  const userData = store.get('userData')
  const detailFetching = useSelector((state) => state.booking.detailFetching)
  const detailData = useSelector((state) => state.booking.detailData)
  let cancelFetching = useSelector((state) => state.booking.cancelFetching)

  const [isEdit, setIsEdit] = useState(
    userData?.team_member_setting?.allow_member_to_reschedule &&
      userData?.team_member_setting?.allow_member_to_cancel
  )

  let customers =
    detailData && detailData.archived
      ? detailData.archived_customers
      : detailData.customers
  let newCust = {}
  !!customers &&
    customers.map((c) => {
      if (!!newCust[`${c.name}_${c.order_name}`]) {
        newCust[`${c.name}_${c.order_name}`] = {
          ...newCust[`${c.name}_${c.order_name}`],
          count: newCust[`${c.name}_${c.order_name}`].count + 1,
        }
        newCust[`${c.name}_${c.order_name}`].customers.push(c)
      } else {
        newCust[`${c.name}_${c.order_name}`] = {
          name: c.name,
          id: c.id,
          count: 1,
          data: c,
          customers: [c],
        }
      }
    })

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const cancelled = urlParams.get('cancelled')

  useEffect(() => {
    if (props.match.params.id) {
      dispatch(
        BookingActions.bookingsDetailRequest(
          props.match.params.id,
          cancelled == 'true'
        )
      )
    }
  }, [props])

  useEffect(() => {
    if (userData.shop.flow === 'shopify') {
      setIsEdit(
        userData?.team_member_setting?.allow_member_to_reschedule &&
          userData?.team_member_setting?.allow_member_to_cancel
      )
    } else {
      setIsEdit(true)
    }
    if (userData && userData.booking_statuses) {
      let options = userData.booking_statuses.map((s) => {
        return {
          label: s.name,
          value: s.id,
        }
      })
      setStatusOptions([{ label: '-', value: null }, ...options])
    }
  }, [])

  const rescheduleeBooking = () => {
    setShowModal(true)
  }

  const cancelBookingModal = () => {
    setShowConfirmationModal(true)
  }

  const cancelBooking = () => {
    TrackEvent('Cancel Booking', {})
    setCancelLoading(true)
    dispatch(
      BookingActions.bookingsCancelRequest(
        { booking_id: detailData.id },
        () => {
          dispatch(BookingActions.bookingsListRequest({ status: 'upcoming' }))
          setCancelLoading(false)
          setShowConfirmationModal(false)
          history.goBack()
        }
      )
    )
  }

  const cancelAppointment = (customer) => () => {
    dispatch(
      BookingActions.bookingsCancelRequest({ customer_id: customer.id }, () => {
        dispatch(BookingActions.bookingsListRequest({ status: 'upcoming' }))
        if (detailData.customers.length === 1) {
          history.goBack()
        } else {
          dispatch(BookingActions.bookingsDetailRequest(detailData.id))
        }
      })
    )
  }

  const refundAmount = (c) => () => {
    setCustomer(c)
    setShowRefundModal(true)
  }

  const handleRescheduleClose = (bookingId) => {
    setShowModal(false)
    if (bookingId) {
      history.replace(`/dashboard/bookings/${bookingId}?cancelled=false`)
    }
  }

  const restoreGroupBooking = (customers) => () => {
    setCurrentCustomers([customers])
    setSelectedType('restore')
    setShowCountModal(true)
  }

  const handleStatusChange = (customers) => (value) => {
    let params = {
      booking_status_id: value,
    }
    customers.map((c, i) => {
      params.count = i
      dispatch(
        BookingActions.updateCustomer(c.id, params, (count) => {
          if (count === customers.length - 1) {
            dispatch(BookingActions.bookingsDetailRequest(detailData.id))
          }
          setStatusIndex(-1)
        })
      )
    })
  }

  const getCustomerDetailRow = () => {
    const customers = detailData?.customers?.map((cust, i) => {
      let checkedIn = detailData?.customers.filter((c) => c.checkin)
      let option = statusOptions.find((s) => s.value === cust.booking_status_id)
      return [
        {
          value: (
            <>
              <p>{cust.name}</p>
              {checkedIn.length > 0 ? (
                <p className="text-green-600 text-xs">
                  Checked In x {checkedIn.length}
                </p>
              ) : (
                ''
              )}
            </>
          ),
        },
        cust.email,
        cust?.payload?.phone ?? '',
        {
          value: (
            <div className="flex flex-col gap-2">
              {!!cust.custom_fields && !!cust.custom_fields.length > 0 ? (
                <Button
                  title="Custom Questions"
                  onClick={() => {
                    setCustomer(cust)
                    setShowCfModal(true)
                  }}
                  type="text"
                />
              ) : (
                <div>-</div>
              )}
              {!!cust.stripe_payload && !!cust.stripe_payload.data ? (
                <Button
                  title="Billing Info"
                  onClick={() => {
                    setCustomer(cust)
                    setShowBillingInfo(true)
                  }}
                  type="text"
                />
              ) : (
                <div>-</div>
              )}
            </div>
          ),
        },
        {
          value: (
            <>
              {cancelled === 'true' ? (
                CANCELLED_SCENARIO[cust.booking_cancelled_by] || ''
              ) : !!option ? (
                <>
                  {statusIndex !== i ? (
                    <div>
                      <p>{option.label}</p>
                      {!cancelled == 'true' && (
                        <div
                          onClick={() => setStatusIndex(i)}
                          style={{ marginLeft: 2, height: 16 }}
                        >
                          <img
                            src={`${AWS_URL}/booking-details/edit.png`}
                            style={{ height: 15, width: 15 }}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div style={{ width: 'auto' }}>
                      <Select
                        label=""
                        options={statusOptions}
                        onChange={handleStatusChange(newCust[cust].customers)}
                        value={cust.booking_status_id}
                      />
                    </div>
                  )}
                </>
              ) : (
                ''
              )}
            </>
          ),
        },
        {
          value: (
            <>
              {cancelled === 'true' ? (
                <Button
                  type="text"
                  title="Restore Booking"
                  onClick={restoreGroupBooking(cust)}
                  className="hover:underline"
                />
              ) : (
                <div className="inline-flex gap-2">
                  {!!cust.stripe_payload && cust.stripe_payload.data && (
                    <Button
                      title="Refund"
                      onClick={refundAmount(cust)}
                      type="default"
                    />
                  )}

                  {isEdit && (
                    <>
                      {cancelled != 'true' && (
                        <div className="flex items-center gap-1">
                          <Tooltip title="Checkin">
                            {checkedIn.length < detailData.customers.length ? (
                              <Button
                                title={
                                  <BadgeCheckIcon className="w-5 text-green-500" />
                                }
                                onClick={checkin(cust)}
                              />
                            ) : (
                              <>
                                {!detailData.customers[0].checkin && (
                                  <Button
                                    title={
                                      <BadgeCheckIcon className="w-5 text-green-500" />
                                    }
                                    onClick={checkin(cust)}
                                  />
                                )}
                              </>
                            )}
                          </Tooltip>
                          {/* )} */}

                          <Tooltip title="Cancel Appointment">
                            <Button
                              title={<TrashIcon className="w-5 text-red-500" />}
                              onClick={cancelAppointment(cust)}
                              type="destructiveText"
                              loading={cancelFetching}
                            />
                          </Tooltip>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          ),
        },
      ]
    })
    return customers
  }

  const selectCount = (count) => {
    if (count > currentCustomers.length) {
      count = currentCustomers.length
    }
    let updatedCustomers = [...currentCustomers].splice(0, count)
    setCurrentCustomers(updatedCustomers)
    switch (selectedType) {
      case 'restore':
        restoreBooking(updatedCustomers)()
        break
      case 'reschedule':
        setShowModal(true)
        break
      case 'cancel':
        cancelAppointment(updatedCustomers)()
        break
      case 'checkin':
        checkin(updatedCustomers)()
        break
    }
  }

  const rescheduleMultiAppointment = (customers) => () => {
    setCurrentCustomers(customers)
    setSelectedType('reschedule')
    setShowCountModal(true)
  }

  const cancelMultiAppointment = (customers) => () => {
    setCurrentCustomers(customers)
    setSelectedType('cancel')
    setShowCountModal(true)
  }

  const checkinMulti = (customers) => () => {
    setCurrentCustomers(customers)
    setSelectedType('checkin')
    setShowCountModal(true)
  }

  const checkin = (cust) => () => {
    let customers
    if (!Array.isArray(cust)) {
      customers = [cust]
    } else {
      customers = cust
    }
    let params = {
      checkin: true,
    }
    customers.map((c, i) => {
      params.count = i
      dispatch(
        BookingActions.updateCustomer(c.id, params, (count) => {
          if (count === customers.length - 1) {
            dispatch(BookingActions.bookingsDetailRequest(detailData.id))
          }
        })
      )
    })
  }

  const restoreBooking = (customers) => () => {
    setLoading(true)
    dispatch(
      BookingActions.bookingsRestoreRequest(
        customers[0].cancelled_booking_id,
        { cancelled_customer_ids: customers.map((cust) => cust.id) },
        (response) => {
          setLoading(false)
          dispatch(NavigationActions.setNavigation('upcoming'))
          history.push(`/dashboard/bookings`)
        }
      )
    )
  }

  const removeNote = (note) => () => {
    dispatch(
      BookingActions.bookingNoteDeleteRequest(
        detailData.id,
        { note_id: note.id },
        (resp) => {
          dispatch(BookingActions.bookingsDetailRequest(detailData.id))
        }
      )
    )
  }

  useEffect(() => {
    if (detailData && detailData.customers) {
      setCustomerTableData(getCustomerDetailRow())
    }
  }, [detailData])

  return (
    <div>
      <Container containerClass="my-6">
        <div
          onClick={history.goBack}
          className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
        >
          <ChevronLeftIcon
            className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Booking
        </div>
        <div className="sm:flex-row flex-col inline-flex justify-between gap-4 items-center w-full">
          <div className="inline-flex justify-between gap-4 items-center">
            <h1 className="text-green-600 font-bold text-2xl">
              {detailData?.product_name}
            </h1>
            <p className="px-2 py-0.5 rounded-lg text-xs text-blue-500 bg-blue-200">
              {moment(detailData?.selected_time).isBefore(moment())
                ? 'Past'
                : 'Upcoming'}
            </p>
          </div>
          <div
            className="inline-flex justify-between items-center"
            style={{ fontSize: 14, fontWeight: 600, gap: 10 }}
          >
            <CalendarIcon width={20} className="text-green-600" />
            {moment(detailData?.selected_time).format(
              "DD MMM' YYYY | HH:mm A"
            )}{' '}
            -{' '}
            {moment(detailData?.selected_time)
              .add(detailData.duration, 'minute')
              .format('HH:mm A')}
          </div>
        </div>
        <div className="sm:flex-row flex-col inline-flex mt-6 sm:gap-12 gap-6 justify-between sm:items-center w-full">
          <div className="inline-flex gap-12">
            <div className="flex flex-col justify-between">
              <p className="text-green-600 text-base font-bold">
                {detailData?.duration} Minutes
              </p>
              <p className="text-sm flex gap-1 items-center">
                Event duration
                {userData.shop.flow !== 'shopify' && (
                  <span onClick={() => setShowEditDurationModal(true)}>
                    <PencilAltIcon className="w-4 cursor-pointer" />
                  </span>
                )}
              </p>
            </div>
          </div>
          {cancelled !== 'true' && (
            <>
              <div className="inline-flex gap-4">
                {userData?.shop.flow === 'shopify' ? (
                  <>
                    {userData?.team_member_setting?.allow_member_to_cancel && (
                      <Button
                        title="Cancel"
                        onClick={cancelBookingModal}
                        type="destructive"
                      />
                    )}
                  </>
                ) : (
                  <Button
                    title="Cancel"
                    onClick={cancelBookingModal}
                    type="destructive"
                  />
                )}

                {userData?.shop.flow === 'shopify' ? (
                  <>
                    {userData?.team_member_setting
                      ?.allow_member_to_reschedule && (
                      <Button
                        title="Reschedule"
                        onClick={rescheduleeBooking}
                        type="default"
                      />
                    )}
                  </>
                ) : (
                  <Button
                    title="Reschedule"
                    onClick={rescheduleeBooking}
                    type="default"
                  />
                )}

                {detailData?.location_value && (
                  <Button
                    title={detailData?.location_value}
                    className="inline-flex gap-2 items-center px-3 py-1 rounded-lg border border-blue-600 text-blue-600"
                    iconLeft={<LinkIcon width={12} />}
                    onClick={() => {
                      window.open(detailData?.location_value, '_blank')
                    }}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </Container>

      {detailFetching ? (
        <Spinner />
      ) : (
        <>
          <CardHeading title="Customer Details" />
          <div className="h-3" />

          {!!detailData && detailData.customers && (
            <Table
              headerClassName="bg-transparent text-black"
              headers={[
                'Customer Name',
                'Email ID',
                'Contact number',
                'Additional Info',
                {
                  value: <>{cancelled == 'true' ? 'Cancelled By' : 'Status'}</>,
                },
                'Actions',
              ]}
              data={customerTableData}
            />
          )}
          {cancelled !== 'true' && (
            <Card>
              <CardHeading
                title="Personal Notes"
                rightSection={
                  <>
                    <Button
                      title="+Note"
                      className="underline text-blue-800"
                      onClick={() => setShowNoteModal(true)}
                    />
                  </>
                }
              />
              <CardSection>
                {!!detailData &&
                !!detailData.notes &&
                detailData.notes.length > 0 ? (
                  <div className="mt-3">
                    <>
                      <div className="grid grid-cols-3">
                        <p className="text-sm">Note</p>
                        <p className="text-sm">Created At</p>
                        <p className="text-sm">ACTIONS</p>
                      </div>

                      {detailData.notes.map((n, i) => {
                        return (
                          <div className="grid-cols-3 place-items-center sm:place-items-start	grid pt-3">
                            <p className="text-sm">{n.note}</p>

                            <p className="text-sm pt-3">
                              {moment(n.updated_at).format(
                                'ddd, MMM D, YYYY h:mm A'
                              )}
                            </p>

                            <div className="flex items-center">
                              <Button
                                onClick={removeNote(n)}
                                type="destructiveText"
                                title="Remove"
                                className="text-sm"
                              />
                            </div>
                          </div>
                        )
                      })}
                    </>
                  </div>
                ) : (
                  <p className="mt-4 mb-4 pr-4 text-sm text-gray-700">
                    No Note Added Set. Click on '+ Note' to add a new personal
                    note for this booking
                  </p>
                )}
              </CardSection>
            </Card>
          )}
        </>
      )}

      <RescheduleBookingDetails
        appointmentId={detailData ? detailData?.appointment?.id : ''}
        showModal={showModal}
        handleClose={handleRescheduleClose}
        title="Reschedule Booking"
        bookingId={detailData.id}
        customerId={customer && customer.id ? customer.id : null}
        durationUuid={detailData?.appointment?.duration_uuid.replace(
          'gid://shopify/ProductVariant/',
          ''
        )}
      />

      <RefundAmountModal
        showModal={showRefundModal}
        handleClose={() => {
          setShowRefundModal(false)
        }}
        customer={customer || null}
      />

      <ViewQuestionsModal
        showModal={showCfModal}
        handleClose={() => {
          setShowCfModal(false)
        }}
        customer={customer || null}
      />

      <ViewBillingInfo
        showModal={showBillingInfo}
        handleClose={() => {
          setShowBillingInfo(false)
        }}
        customer={customer || null}
      />
      {showEditDurationModal && (
        <EditDurationModal
          showModal={showEditDurationModal}
          handleClose={() => setShowEditDurationModal(false)}
          title="Change Duration"
          defaultValue={detailData.duration}
          bookingId={detailData.id}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {showCountModal && (
        <ShowCountModal
          showModal={showCountModal}
          setShowModal={setShowCountModal}
          handleClose={() => {
            setShowCountModal(false)
          }}
          title="Select customers count"
          selectCount={selectCount}
          type={selectedType}
          maxCount={currentCustomers.length}
        />
      )}
      {showNoteModal && (
        <NoteModal
          showModal={showNoteModal}
          setShowModal={setShowNoteModal}
          handleClose={() => {
            setShowNoteModal(false)
          }}
          bookingId={detailData.id}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          title={'Cancel Booking'}
          showModal={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
          buttonText="Yes, Cancel"
          loading={cancelLoading}
          onClick={cancelBooking}
          text="Are you sure, you want to cancel this booking? This cannot be undone!"
        />
      )}
    </div>
  )
}

export default ManageBooking
