import React, { useState, useCallback, useEffect } from 'react'
import { bundleUrl } from 'Themes/constants'
import { Crisp } from 'crisp-sdk-web'
import Button from 'Components/Button'

export default function PublicUrl(props) {
  const params = new URLSearchParams(window.location.search)

  const getConfigObj = () => {
    let obj = {
      shop_uuid: props.match.params.id,
      type: params.get('type') || 'embed',
    }
    switch (params.get('type')) {
      case 'embed':
        obj['querySelector'] = '#appointo'
        break
      case 'floatingButton':
        obj['button_styles'] = {
          color: '#3E3E3E',
          backgroundColor: 'green',
        }
        obj['button_text'] = 'Book Now'
        break
      case 'popup':
        obj['querySelector'] = '#appointo'
        break
      default:
        obj['querySelector'] = '#appointo'
    }
    if (params.get('product_uuid')) {
      obj['product_uuid'] = params.get('product_uuid')
    }
    return obj
  }

  useEffect(() => {
    window.appointo = {
      config: getConfigObj(),
    }
    const script = document.createElement('script')
    script.src = bundleUrl
    document.body.appendChild(script)

    setTimeout(() => {
      // Crisp.chat.hide()
    }, 2000)
  }, [])

  return (
    <>
      {params.get('type') === 'popup' && (
        <div
          className="flex w-full justify-center items-center"
          style={{
            minHeight: '100vh',
            maxHeight: '100vh',
          }}
        >
          <button
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            id="appointo"
          >
            Book
          </button>
        </div>
      )}
      {(params.get('type') !== 'popup' || params.get('type') === '') && (
        <div id="appointo"></div>
      )}
    </>
  )
}
