import Button from 'Components/Button'
import Table from 'Components/Table'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'Components/Spinner'
import EmptyState from 'Components/EmptyState'
import CustomerActions from 'Redux/CustomerRedux'
import useDebounce from 'Hooks/debounce'
import CustomerDetails from './CustomerDetails'
import Input from 'Components/Input'
import { ItemsPerPage } from 'Redux/genericReducers'
import Select from 'Components/Select'
import AppointoPagination from 'Components/AppointoPagination'
import { MANAGE_CUSTOMER } from 'Routes/routes'
import { useHistory } from 'react-router-dom'
import Heading from 'Components/Heading'

const Customer = () => {
  const history = useHistory()
  const customerHeaders = [
    'Name',
    'Email',
    'Phone',
    'Total Booking Count',
    'Unread Message Count',
    '',
  ]
  const customersFetching = useSelector((state) => state.customer.listFetching)
  const customersList = useSelector((state) => state.customer.list)
  const listDataOffset = useSelector((state) => state.customer.listDataOffset)
  const [list, setList] = useState([])
  const [status, setStatus] = useState('all')
  const dispatch = useDispatch()
  const [queryValue, setQueryValue] = useState('')
  const filters = []
  const appliedFilters = []
  const debouncedQueryValue = useDebounce(queryValue, 300)

  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value)
  }, [])

  const handleQueryValueRemove = useCallback(() => {
    setQueryValue('')
    dispatch(CustomerActions.customersListRequest({}, 0))
  }, [customersList])

  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove()
  }, [handleQueryValueRemove])

  const manageCustomer = (c) => () => {
    history.push(`${MANAGE_CUSTOMER}?name=${c.name}&email=${c.email}`)
  }

  useEffect(() => {
    if (queryValue.length >= 3) {
      dispatch(
        CustomerActions.customersListRequest(
          {
            search_term: queryValue,
            status: status,
          },
          0
        )
      )
    }
    if (queryValue.length == 0) {
      dispatch(CustomerActions.customersListRequest({ status: status }, 0))
    }
  }, [debouncedQueryValue])

  const onPrevious = () => {
    dispatch(
      CustomerActions.customersListRequest(
        {
          search_term: queryValue,
          status: status,
        },
        listDataOffset - ItemsPerPage
      )
    )
  }

  const onNext = () => {
    dispatch(
      CustomerActions.customersListRequest(
        {
          search_term: queryValue,
          status: status,
        },
        listDataOffset + ItemsPerPage
      )
    )
  }

  useEffect(() => {
    if (customersList?.length > 0) {
      let s = customersList.map((b, i) => {
        return [
          b.name,
          b.email,
          b.phone ? b.phone : '-',
          b.unread_message_count,
          b.booking_count,
          {
            value: (
              <div className="flex gap-2.5">
                <Button
                  type="defaultGray"
                  title="Manage"
                  onClick={manageCustomer(b)}
                />
              </div>
            ),
          },
        ]
      })
      setList(s)
    }
  }, [customersList, customersFetching])

  useEffect(() => {
    dispatch(CustomerActions.customersListRequest({}, 0))
  }, [])
  return (
    <>
      <div className="mb-6">
        <Heading title="Waitlist" />
      </div>

      {customersFetching ? (
        <Spinner />
      ) : list.length === 0 ? (
        <EmptyState header="No Customers" />
      ) : (
        <>
          <div className="bg-white p-5 rounded-tr-md rounded-tl-md">
            <div className="grid grid-cols-12 mb-4 justify-between gap-4 items-center">
              <div className="col-span-12 sm:col-span-8">
                <Input
                  value={queryValue}
                  placeholder="Filter customers by name or email"
                  onChange={(value) => setQueryValue(value)}
                />
              </div>
              <div className="col-span-12 mt-4 sm:col-span-4">
                <Select
                  label="Status"
                  options={[
                    { label: 'All Customers', value: 'all' },
                    { label: 'Unread Messages Customers', value: 'unread' },
                  ]}
                  selected={status}
                  setSelected={(value) => {
                    setStatus(value)
                    dispatch(
                      CustomerActions.customersListRequest(
                        {
                          search_term: queryValue,
                          status: value,
                        },
                        0
                      )
                    )
                  }}
                  className="text-sm"
                />
              </div>
            </div>
          </div>

          {customersList.length > 0 ? (
            <>
              <Table headers={customerHeaders} data={list} />
              <AppointoPagination
                offset={listDataOffset}
                onPrevious={onPrevious}
                list={list}
                onNext={onNext}
              />
            </>
          ) : (
            <p className="text-md bg-white p-5 text-center">
              Oops. No Customer found. Please change the search term or add wait
              for a booking to come in
            </p>
          )}
        </>
      )}
    </>
  )
}

export default Customer
