import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import BookingActions from 'Redux/BookingRedux'
import VariantActions from 'Redux/VariantRedux'
import CustomerActions from 'Redux/CustomerRedux'
import moment from 'moment'
import axios from 'axios'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import { isApple } from 'Themes/constants'
import SelectTime from '../Subscriptions/SelectTime'
import AnswerCustomFields from '../Subscriptions/AnswerCustomFields'
import Card from 'Components/Card'
import CardSection from 'Components/CardSection'
import Heading from 'Components/Heading'
import Input from 'Components/Input'
import Checkbox from 'Components/Checkbox'
import Toast from 'Components/Toast'
import Button from 'Components/Button'
import store from 'store'
import { ChevronLeftIcon } from '@heroicons/react/outline'

function ManualBookingView(props) {
  const dispatch = useDispatch()
  const [selectedProduct, setSelectedProduct] = useState(
    props.selectedProduct || {}
  )
  const [selectedVariant, setSelectedVariant] = useState(
    props.selectedVariant || {}
  )
  const [selectedCustomer, setSelectedCustomer] = useState(
    props.selectedCustomer || {}
  )
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([])
  const [selectedDate, setSelectedDate] = useState()
  const [selectedSlot, setSelectedSlot] = useState({})
  const [selectedEndDate, setSelectedEndDate] = useState()
  const [currentPage, setCurrentPage] = useState('selectProduct')
  const [loading, setLoading] = useState(false)
  const [override, setOverride] = useState(false)
  const [createDraftOrder, setCreateDraftOrder] = useState(false)
  const [teamMembers, setTeamMembers] = useState([])
  const [customFields, setCustomFields] = useState([])
  const [customFieldsValues, setCustomFieldsValues] = useState({})
  const [allFieldsEntered, setAllFieldsEntered] = useState(false)
  const [generatedLink, setGeneratedLink] = useState('')
  const [quantity, setQuantity] = useState(`1`)
  const [fullLoading, setFullLoading] = useState(false)
  const [selectLoading, setSelectLoading] = useState(false)
  const [appointments, setAppointments] = useState([])
  const [customers, setCustomers] = useState([])
  const [showCustomerToast, setShowCustomerToast] = useState(false)
  const [customersKey, setCustomersKey] = useState('appt')
  const [newCustomer, setNewCustomer] = useState({})
  const [durationOption, setDurationOption] = useState(['fixed'])
  const [customDuration, setCustomDuration] = useState('')

  const userData = store.get('userData')

  const loadAppointments = (input, callback) => {
    if (input.length > 3) {
      setSelectLoading(true)
      dispatch(
        VariantActions.variantsSearchRequest(
          {
            search_term: input,
            send_extra_info: true,
          },
          (data) => {
            let list = data.map((t) => {
              return {
                label: t.name,
                value: t.duration_uuid,
              }
            })
            setSelectLoading(false)
            callback(list)
          }
        )
      )
    } else if (input.length === 0) {
      setSelectLoading(true)
      dispatch(
        VariantActions.variantsSearchRequest(
          {
            send_extra_info: true,
          },
          (data) => {
            let list = data.map((t) => {
              return {
                label: t.name,
                value: t.duration_uuid,
              }
            })
            setAppointments(data)
            setSelectLoading(false)
            callback(list)

            if (!!props.selectedVariant) {
              setTimeout(() => {
                handleAppointmentChange(props.selectedVariant, data)
              }, 300)
            }
          }
        )
      )
    }
  }

  const loadCustomers = (input, callback) => {
    if (input.length >= 3) {
      setSelectLoading(true)
      dispatch(
        CustomerActions.customersListRequest(
          {
            search_term: input,
          },
          {},
          (data) => {
            let newData = [...data]
            if (!!newCustomer && Object.keys(newCustomer).length > 0) {
              newData = [newCustomer, ...data]
            }
            let list = newData.map((t) => {
              return {
                label: `${t.email || ''} (${t.name})`,
                value: `${t.email}_${t.phone}`,
              }
            })
            setCustomers(newData)
            setSelectLoading(false)
            callback(list)
          }
        )
      )
    } else if (input.length === 0) {
      setSelectLoading(true)
      dispatch(
        CustomerActions.customersListRequest({}, {}, (data) => {
          let newData = [...data]
          if (!!newCustomer && Object.keys(newCustomer).length > 0) {
            newData = [newCustomer, ...data]
          }
          let list = newData.map((t) => {
            return {
              label: `${t.email || ''} (${t.name})`,
              value: `${t.email}_${t.phone}`,
            }
          })
          setCustomers(newData)
          setSelectLoading(false)
          callback(list)
        })
      )
    }
  }

  useEffect(() => {
    if (!!props.showModal && props.fromBookingDetails) {
    }

    if (props.fromCalendar) {
      setSelectedDate(moment(props.selectedSlot.start).format())

      var duration = moment.duration(
        moment(props.selectedSlot.end).diff(moment(props.selectedSlot.start))
      )
      setDurationOption(['custom'])
      setCustomDuration(`${duration.asMinutes()}`)
    }
  }, [props])

  const generateLink = () => {
    let params = {
      duration_uuid: selectedVariant.value,
      variant_name: selectedVariant.label,
      override: override,
    }

    let customer = customers.find(
      (c) => `${c.email}_${c.phone}` === selectedCustomer.value
    )
    if (!!customer) {
      params.email = customer.email
      params.name = customer.name
      params.phone = customer.phone
      if (customer.id && customer.id !== '-') {
        params._customer_uuid = Number(customer.id)
      }
    } else {
      params.email = selectedCustomer.email
      params.name = selectedCustomer.name
      params.phone = selectedCustomer.phone
      if (selectedCustomer.id && selectedCustomer.id !== '-') {
        params._customer_uuid = Number(selectedCustomer.id)
      }
    }

    if (selectedTeamMembers.length > 0) {
      params.impersonated_tms = selectedTeamMembers
    }

    if (userData.shop.flow === 'shopify') {
      params.impersonated_tms = userData.id
    }

    if (createDraftOrder) {
      params.create_draft_order = true
    }

    setLoading(true)
    dispatch(
      BookingActions.generateBookingLink(params, (data) => {
        setLoading(false)
        if (data.booking_link) {
          if (isApple) {
            // const redirect = Redirect.create(window.app)
            // redirect.dispatch(Redirect.Action.REMOTE, {
            //   url: `${data.booking_link}`,
            //   newContext: true,
            // })
          } else {
            window.open(`${data.booking_link}`, '_blank')
          }

          props.handleClose()
        }
      })
    )
  }

  const handleAdmin = async () => {
    let appt = appointments.find(
      (a) => a.duration_uuid === selectedVariant.value
    )
    let finalQty = quantity
    if (!!selectedEndDate && !appt.appointment_config.fixed_multi_day_price) {
      let startDate = moment(selectedDate)
      let endDate = moment(selectedEndDate)
      finalQty = endDate.diff(startDate, 'day') + 1
    }

    let params = {
      duration_uuid: selectedVariant.value.replace(
        'gid://shopify/ProductVariant/',
        ''
      ),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timestring: selectedDate,
      quantity: parseInt(finalQty, 10),
      group_ids:
        !!selectedSlot.available_groups &&
        selectedSlot.available_groups.length > 0
          ? selectedSlot.available_groups
          : [],
      override: override,
      custom_fields: [],
    }

    if (Object.values(customFieldsValues).length > 0) {
      let custom_fields = []

      Object.keys(customFieldsValues).map((cfv) => {
        custom_fields.push({
          custom_field_id: parseInt(cfv, 10),
          value: customFieldsValues[cfv],
        })
      })
      params.custom_fields = custom_fields
    }

    let customer = customers.find(
      (c) => `${c.email}_${c.phone}` === selectedCustomer.value
    )
    if (!!customer) {
      params.email = customer.email
      params.name = customer.name
      params.phone = customer.phone
      if (customer.id && customer.id !== '-') {
        params._customer_uuid = customer.id
      }
    } else {
      params.email = selectedCustomer.email
      params.name = selectedCustomer.name
      params.phone = selectedCustomer.phone
      if (selectedCustomer.id && selectedCustomer.id !== '-') {
        params._customer_uuid = selectedCustomer.id
      }
    }
    params.shopify_domain = userData.shop.shopify_domain
    params.flow = 'manual_booking'

    params.customer_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    //params._uuid = unique_id

    if (!!selectedEndDate) {
      params.multi_day_qty = quantity || 1
    }

    // if (props.isWeb) {
    //   params.impersonated_tm = props.user.id
    // } else {
    if (selectedTeamMembers.length > 0) {
      params.impersonated_tms = selectedTeamMembers
      params.team_member_ids = selectedTeamMembers
    }
    // }

    if (createDraftOrder) {
      params.create_draft_order = true
    }
    console.log('durationOption', durationOption, customDuration)

    if (durationOption.includes('custom')) {
      params.duration = parseInt(customDuration, 10)
      params.override = true
    }

    setLoading(true)

    let slotUrl = `${process.env.REACT_APP_BASE_URL}/scripttag/book_slot`
    await axios.post(slotUrl, params)
    setLoading(false)
    props.handleClose(true)
  }

  const getTms = (appt) => {
    let tms = []
    appt.team_members.map((a) => {
      let tm = appt.appointment_team_members.find(
        (t) => a.id === t.team_member_id
      )
      tms.push({
        label:
          tm.category === 'default'
            ? `${a.email} (${a.name})`
            : `${a.email} - ${tm.category}(${a.name})`,
        value: a.id,
      })
    })
    return tms
  }

  // const handlePos = () => {
  //   var cart = Cart.create(app)

  //   app.featuresAvailable().then(function (state) {
  //     console.log('features2', state.Cart['APP::CART::UPDATE'])
  //   })

  //   setLoading(true)

  //   let appt = appointments.find(
  //     (a) => a.duration_uuid === selectedVariant.value
  //   )

  //   var unsubscriber = cart.subscribe(Cart.Action.UPDATE, function (payload) {
  //     let index = payload.data.lineItems.length - 1

  //     var ub = cart.subscribe(Cart.Action.UPDATE, function (payload) {
  //       console.log('[Client] setLineItemProperties', payload)

  //       const toastNotice = Toast.create(app, {
  //         message: 'Item has been added to the cart',
  //       })
  //       toastNotice.dispatch(Toast.Action.SHOW)

  //       const pos = Pos.create(app)
  //       pos.dispatch(Pos.Action.CLOSE)

  //       ub()
  //     })

  //     let data = {}

  //     if (!!selectedEndDate) {
  //       data = {
  //         Date: moment(selectedDate).format('MMM DD YYYY'),
  //         _start_time: moment(selectedDate).format('YYYY-MM-DD'),
  //         _timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  //       }

  //       if (appt.appointment_config.fixed_multi_day_price) {
  //         let startDate = moment(selectedDate)
  //         let endDate = moment(selectedEndDate)
  //         quantity = endDate.diff(startDate, 'day') + 1
  //         data['_quantity'] = `${quantity}`
  //       }
  //     } else {
  //       data = {
  //         'Date & Time': moment(selectedDate).format('dddd, MMM DD hh:mm a'),
  //         _start_time: selectedDate,
  //         _timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  //       }
  //     }

  //     if (Object.values(customFieldsValues).length > 0) {
  //       Object.keys(customFieldsValues).map((cfv) => {
  //         data[`_${cfv}`] = customFieldsValues[cfv]
  //       })
  //     }

  //     cart.dispatch(Cart.Action.SET_LINE_ITEM_PROPERTIES, {
  //       index: index,
  //       data: data,
  //     })

  //     unsubscriber()
  //   })

  //   let variantId = parseInt(
  //     selectedVariant.value.replace('gid://shopify/ProductVariant/', ''),
  //     10
  //   )

  //   let quantity = 1
  //   if (!!selectedEndDate && !appt.appointment_config.fixed_multi_day_price) {
  //     let startDate = moment(selectedDate)
  //     let endDate = moment(selectedEndDate)
  //     quantity = endDate.diff(startDate, 'day') + 1
  //   }

  //   cart.dispatch(Cart.Action.ADD_LINE_ITEM, {
  //     data: {
  //       variantId: variantId,
  //       quantity: quantity,
  //     },
  //   })
  // }

  const handleAppointmentChange = (option, appts) => {
    let apts = appts || appointments

    let appt = apts.find((a) => a.duration_uuid === option.value)
    if (!!appt && appt.team_members?.length > 0) {
      let tms = getTms(appt)
      setTeamMembers(tms)
      setSelectedTeamMembers([tms[0].value])
    } else {
      setTeamMembers([])
      setSelectedTeamMembers([])
    }

    if (!!appt && !!appt.custom_fields && appt.custom_fields.length > 0) {
      setCustomFields(appt.custom_fields)
      setCustomFieldsValues([])
    } else {
      setCustomFields([])
      setCustomFieldsValues([])
    }

    if (!props.fromCalendar) {
      setSelectedDate()
      setSelectedEndDate()
    }

    if (!!appt && !!appt.product_detail) {
      setSelectedProduct({
        scenario: appt.product_detail.scenario,
      })
    }

    if (!!appt) {
    }

    setSelectedVariant(option)
  }

  const handleSelectChange = (option) => {
    if (!!option) {
      setSelectedTeamMembers(option.map((o) => o.value))
    } else {
      setSelectedTeamMembers([])
    }
  }

  return (
    <>
      {showCustomerToast && (
        <Toast
          content="Customer created successfully"
          onDismiss={() => setShowCustomerToast(false)}
        />
      )}
      <div className="mb-5">
        <Heading title="Manual Booking" />
      </div>
      <Card>
        <div
          onClick={() => props.setShowManualModal(false)}
          className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer my-4"
        >
          <ChevronLeftIcon
            className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Booking
        </div>
        <CardSection>
          {props.selectedSlot && (
            <div style={{ marginBottom: 20 }}>
              <p style={{ fontWeight: 'bold', marginBottom: 4 }}>
                Selected Start Time:{' '}
                {moment(props.selectedSlot.start).format('Do, MMM YY | h:mm a')}
              </p>
              <p style={{ fontWeight: 'bold' }}>
                Selected End Time:{' '}
                {moment(props.selectedSlot.end).format('Do, MMM YY | h:mm a')}
              </p>
            </div>
          )}

          <div className="flex gap-4 items-center flex-col sm:flex-row">
            <div className="w-full sm:w-1/2">
              <p className="text-sm">Select Appointment</p>
              <AsyncSelect
                label=""
                className="basic-single"
                classNamePrefix="select"
                isSearchable
                isRtl={false}
                onChange={(option) => {
                  handleAppointmentChange(option)
                }}
                placeholder="Search or select an appointment"
                value={selectedVariant}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                loadOptions={loadAppointments}
                defaultOptions
                isLoading={selectLoading}
              />
              <p style={{ fontSize: 13, color: '#637381' }}>
                Search and select your required appointments
              </p>
            </div>
            {!props.isPos && (
              <div className="w-full sm:w-1/2">
                <p className="text-sm">Select Customer</p>
                <AsyncSelect
                  label=""
                  key={customersKey}
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable
                  isRtl={false}
                  onChange={(option) => {
                    setSelectedCustomer(option)
                  }}
                  placeholder="Search or select a customer"
                  value={selectedCustomer}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  loadOptions={loadCustomers}
                  defaultOptions
                  isLoading={selectLoading}
                />
                <p style={{ fontSize: 13, color: '#637381' }}>
                  Search and select your required customers
                </p>
              </div>
            )}
          </div>

          {teamMembers.length > 0 && (
            <div style={{ marginTop: 10 }}>
              <p>Select Team Member</p>
              <Select
                label=""
                isMulti
                className="basic-single"
                classNamePrefix="select"
                isSearchable
                defaultValue={teamMembers.filter(
                  (s) =>
                    !!selectedTeamMembers.find((t) => {
                      return s.value === t
                    })
                )}
                options={teamMembers}
                onChange={handleSelectChange}
                value={teamMembers.filter(
                  (s) =>
                    !!selectedTeamMembers.find((t) => {
                      return s.value === t
                    })
                )}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
              />
            </div>
          )}

          {!props.fromCalendar &&
            selectedVariant.value &&
            (props.isPos ? true : selectedCustomer.value) && (
              <SelectTime
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                variantId={selectedVariant.value}
                shop={props.shop}
                selectedProduct={selectedProduct}
                setSelectedEndDate={setSelectedEndDate}
                setSelectedSlot={setSelectedSlot}
                setOverride={setOverride}
                quantity={quantity}
                setQuantity={setQuantity}
                tms={selectedTeamMembers}
                user={props.user}
                fromView
              />
            )}

          {customFields.length > 0 && !!selectedDate && (
            <AnswerCustomFields
              customFields={customFields}
              customFieldsValues={customFieldsValues}
              setCustomFieldsValues={setCustomFieldsValues}
              allFieldsEntered={allFieldsEntered}
              setAllFieldsEntered={setAllFieldsEntered}
              fromView
            />
          )}

          {selectedVariant.value && !!selectedDate && !props.isPos && (
            <div style={{ marginTop: 20 }}>
              <p className="text-sm my-2">Event Duration</p>
              <Checkbox
                title="Use Custom Duration"
                checked={durationOption}
                onClick={(value) => {
                  if (value) setDurationOption('custom')
                  else setDurationOption('')
                }}
              />

              {durationOption.includes('custom') && (
                <div style={{ marginTop: 8, marginLeft: 8 }}>
                  <Input
                    label="Custom Duration (in minutes)"
                    onChange={(value) => {
                      setCustomDuration(value)
                    }}
                    placeholder=""
                    value={customDuration}
                    type="number"
                  />
                </div>
              )}
            </div>
          )}

          {selectedVariant.value && !!selectedDate && (
            <>
              {props.isPos ? (
                <div
                  style={{
                    paddingTop: 30,
                    paddingBottom: 20,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {/* <Button
                      onClick={handlePos}
                      loading={loading}
                      variant="primary"
                    >
                      Add to Cart
                    </Button> */}
                </div>
              ) : (
                selectedCustomer.value && (
                  <>
                    <div className="py-4">
                      <Checkbox
                        title="Create Draft Order"
                        checked={createDraftOrder}
                        onClick={(value) => {
                          setCreateDraftOrder(value)
                        }}
                        subTitle="Enable this if you want to automatically create draft order on generating the link or confirming the booking"
                      />
                    </div>
                    <p className="text-sm">
                      * Generating a link will reset the time and custom fields
                      selection for customers
                    </p>
                    <div className="mt-4 sm:mt-0 flex gap-3 items-center justify-end">
                      {!props.isPos && (
                        <Button
                          onClick={generateLink}
                          loading={loading}
                          type="text"
                          title="Generate Link"
                        />
                      )}

                      <Button
                        title="Confirm Booking"
                        type="primary"
                        loading={loading}
                        onClick={handleAdmin}
                      />
                    </div>
                  </>
                )
              )}
            </>
          )}
        </CardSection>
      </Card>
    </>
  )
}

export default ManualBookingView
