import Button from 'Components/Button'
import Select from 'Components/Select'
import Table from 'Components/Table'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BookingActions from 'Redux/BookingRedux'
import Spinner from 'Components/Spinner'
import EmptyState from 'Components/EmptyState'
import Input from 'Components/Input'
import useDebounce from 'Hooks/debounce'
import SubscriptionDetails from './SubscriptionDetails'
import AppointoPagination from 'Components/AppointoPagination'
import { ItemsPerPage } from 'Redux/genericReducers'
import { CSVDownload, CSVLink } from 'react-csv'
import moment from 'moment'
import ReactDOM from 'react-dom'
import Heading from 'Components/Heading'
import ManualSubscriptionModal from 'Components/Modals/ManualSubscriptionModal'

const headers = [
  'Order No.',
  'Service Name',
  'Customer Name',
  'Subscription Type',
  'Subscription Status',
  'Bookings Status',
  '',
]

let defaultCSVHeaders = [
  { label: 'Order No.', key: 'order_name' },
  { label: 'Service Name', key: 'product_name' },
  { label: 'Customer Name', key: 'customer_name' },
  { label: 'Customer Email', key: 'customer_email' },
  { label: 'Subscription Type', key: 'subscription_type' },
  { label: 'Bookings Redeemed', key: 'bookings_redeemed' },
  { label: 'Max Booking Count', key: 'max_booking_count' },
  { label: 'Next Billing Date', key: 'next_billing_date' },
  { label: 'Status', key: 'status' },
]

const Subscription = () => {
  const [subscriptionStatus, setSubscriptionStatus] = useState('unarchived')
  const [showSubscriptionDetails, setShowSubscriptionDetails] = useState(false)
  const [selectedSubscription, setSelectedSubscription] = useState({})
  const [queryValue, setQueryValue] = useState('')
  const bookingsList = useSelector((state) => state.booking.list)
  const bookingsFetching = useSelector((state) => state.booking.listFetching)
  const listDataOffset = useSelector((state) => state.booking.listDataOffset)
  const [list, setList] = useState([])
  const [csvHeaders, setCsvHeaders] = useState(defaultCSVHeaders)
  const [csvData, setCsvData] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)

  const debouncedQueryValue = useDebounce(queryValue, 300)

  const dispatch = useDispatch()

  useEffect(() => {
    if (queryValue.length >= 3) {
      dispatch(
        BookingActions.bookingsSubscriptionsListRequest({
          search_term: queryValue,
          status: subscriptionStatus,
        }),
        0
      )
    }
    if (queryValue.length == 0) {
      dispatch(
        BookingActions.bookingsSubscriptionsListRequest({
          status: subscriptionStatus,
        }),
        0
      )
    }
  }, [debouncedQueryValue])

  useEffect(() => {
    if (bookingsList?.length > 0) {
      let s = bookingsList.map((b, i) => {
        return [
          b?.metadata?.originOrder?.name,
          !!b.metadata && b.metadata.lines && b.metadata.lines.length > 0
            ? `${b.metadata.lines[0].title} - ${
                b.metadata.lines[0].variantTitle || ''
              }`
            : '',
          b.metadata
            ? `${b?.metadata?.customer?.firstName} ${b?.metadata?.customer?.lastName}`
            : '-',
          b.subscription_type === 'recurring' ? 'Recurring' : 'Fixed',
          b.archived ? 'ARCHIVED' : b.status,
          `${b.curr_booking_count}/${b.max_booking_count}`,
          {
            value: (
              <div className="flex gap-2.5">
                <Button
                  type="defaultGray"
                  title="Manage"
                  onClick={manageSubscription(b)}
                />
              </div>
            ),
          },
        ]
      })
      setList(s)
      exportSubscription()
    }
  }, [bookingsList, bookingsFetching])

  useEffect(() => {
    dispatch(
      BookingActions.bookingsSubscriptionsListRequest({
        status: subscriptionStatus,
      }),
      0
    )
  }, [])

  const onPrevious = () => {
    dispatch(
      BookingActions.bookingsSubscriptionsListRequest(
        {
          search_term: queryValue,
          status: subscriptionStatus,
        },
        listDataOffset - ItemsPerPage
      )
    )
  }

  const onNext = () => {
    dispatch(
      BookingActions.bookingsSubscriptionsListRequest(
        {
          search_term: queryValue,
          status: subscriptionStatus,
        },
        listDataOffset + ItemsPerPage
      )
    )
  }

  const manualSubscription = () => {
    setShowSubscriptionModal(true)
  }

  const manageSubscription = (s) => () => {
    dispatch(BookingActions.bookingsSubscriptionRequest(s.uuid))
    setSelectedSubscription(s)
    setShowSubscriptionDetails(true)
  }

  const exportSubscription = () => {
    let data = bookingsList.map((o) => {
      let m = o.metadata
      return {
        order_name:
          o.metadata && o.metadata.originOrder
            ? o.metadata.originOrder.name
            : '-',
        product_name:
          !!m && m.lines && m.lines.length > 0
            ? `${m.lines[0].title} - ${m.lines[0].variantTitle || ''}`
            : '',
        customer_name:
          !!m && !!m.customer
            ? `${m?.customer?.firstName} ${m?.customer?.lastName}`
            : '',
        customer_email: !!m && !!m.customer ? `${m.customer.email}` : '',
        subscription_type:
          o.subscription_type === 'recurring' ? 'Recurring' : 'Fixed',
        next_billing_date: !!o.next_billing_date
          ? moment(o.next_billing_date).format('YYYY-MM-DD HH:mm')
          : '-',
        bookings_redeemed: o.curr_booking_count,
        max_booking_count: o.max_booking_count,
        status: o.archived ? 'ARCHIVED' : o.status,
      }
    })

    setCsvData(data)
  }

  return (
    <div>
      {showSubscriptionDetails ? (
        <SubscriptionDetails
          handleBack={() => setShowSubscriptionDetails(false)}
        />
      ) : (
        <>
          <div className="mb-6">
            <Heading
              title="Subscriptions"
              rightSection={
                <>
                  {list.length > 0 && (
                    <div className="flex gap-3 items-center">
                      <Button
                        type="defaultGray"
                        title={
                          <>
                            <CSVLink
                              data={csvData}
                              headers={csvHeaders}
                              filename={`subscriptions_${moment(
                                startDate
                              ).format('YYYY-MM-DD')}`}
                            >
                              Download Subscriptions CSV
                            </CSVLink>
                          </>
                        }
                      />
                      <Button
                        type="primary"
                        title="+Manual Subscription"
                        onClick={manualSubscription}
                      />
                    </div>
                  )}
                </>
              }
            />
          </div>

          {bookingsFetching ? (
            <Spinner />
          ) : list.length === 0 ? (
            <EmptyState header="No Subscriptions" />
          ) : (
            <>
              <div className="bg-white p-5 rounded-tr-md rounded-tl-md">
                <div className="sm:grid sm:grid-cols-12 mb-4 sm:justify-between sm:gap-4 sm:items-center">
                  <div className="sm:col-span-8">
                    <div className="pt-5">
                      <Input
                        value={queryValue}
                        placeholder="Filter subscriptions by name or email"
                        onChange={(value) => setQueryValue(value)}
                        label="Filter Subscriptions"
                      />
                    </div>
                  </div>
                  <div className="mt-4 sm:col-span-4">
                    <Select
                      label="Status"
                      options={[
                        { label: 'Un-Archived', value: 'unarchived' },
                        { label: 'Archived', value: 'archived' },
                      ]}
                      selected={subscriptionStatus}
                      setSelected={(value) => {
                        setSubscriptionStatus(value)
                        dispatch(
                          BookingActions.bookingsSubscriptionsListRequest({
                            search_term: queryValue,
                            status: value,
                          }),
                          0
                        )
                      }}
                      className="text-sm"
                    />
                  </div>
                </div>

                {/* <div className="flex justify-end">
                  <Button
                    type="defaultGray"
                    title={
                      <>
                        <CSVLink
                          data={csvData}
                          headers={csvHeaders}
                          filename={`subscriptions_${moment(startDate).format(
                            'YYYY-MM-DD'
                          )}`}
                        >
                          Download Subscriptions CSV
                        </CSVLink>
                      </>
                    }
                  />
                </div> */}
              </div>
              {bookingsList.length > 0 ? (
                <>
                  <Table headers={headers} data={list} />
                  <AppointoPagination
                    offset={listDataOffset}
                    onPrevious={onPrevious}
                    list={list}
                    onNext={onNext}
                  />
                </>
              ) : (
                <p className="text-md bg-white p-5 text-center">
                  Oops! No subscriptions. Please add a subscription to a product
                </p>
              )}
            </>
          )}
        </>
      )}
      {showSubscriptionModal && (
        <ManualSubscriptionModal
          showModal={showSubscriptionModal}
          handleClose={() => {
            setShowSubscriptionModal(false)
          }}
          setShowModal={setShowSubscriptionModal}
        />
      )}
    </div>
  )
}

export default Subscription
